.dashboard_main {
    background: $dashborad_sidebar;
    min-height: 100vh;

    .dashboard_content {
        display: $d_flex;
        width: $w_100;
        overflow: hidden;
        min-height: calc(100vh - 24.6rem);

        .user_detali {
            width: calc(100% - 18.8rem);
            overflow-x: auto;
            min-height: 100vh;
            background: $table_color;

            .user_panel {
                padding: 16px 130px 17px 48px;
                display: $d_flex;
                align-items: $align_items_center;
                justify-content: $justify_content_between;
                background: $dashborad_sidebar;

                @media (max-width:1200px) {
                    padding: 15px 20px 15px 20px !important;
                }

                @media (max-width:991px) {
                    flex-wrap: $flex_wrap;
                }


                .total_user_count {
                    margin-left: 38px;
                    width: 20%;

                    @media (max-width:991px) {
                        width: 30%;
                        margin-left: 20px;
                    }

                    p {
                        color: $black;
                        font-size: $f14;
                        line-height: $lineheight_20;
                        letter-spacing: $letter_02;
                        font-family: "FiraSans-Regular", sans-serif;
                        font-weight: $semi_bold;
                        text-transform: $text_capitalize;

                    }


                }

                .add_user {
                    width: $w_60;
                    display: $d_flex;
                    justify-content: $justify_content_end;
                    cursor: pointer;

                    @media (max-width:991px) {
                        justify-content: left;

                    }

                    .primary {
                        max-width: $w_160;
                        padding: 11px 36px;
                        margin-left: 0;

                        &.mr_10 {
                            max-width: max-content;
                        }

                        @media (max-width:991px) {
                            margin: 20px 0 0;

                        }

                        &.dms_stats_download {
                            background: #946C9D;


                            &:disabled {
                                background-color: $gray_color;
                            }
                        }
                    }
                }
            }
        }
    }

}

.user_search {
    position: relative;
    width: 21.8%;
    min-width: 309px;
    max-width: 309px;

    @media (max-width:1680px) {
        width: 27.5%;
    }

    @media (max-width:991px) {
        width: 55%;
        min-width: 0px;
    }

    .search_image {
        position: $position_absolute;
        left: 7px;
        // bottom: 12px;
        width: $w_19;
        height: $h_19;
        z-index: 99;
        top: 14px;
    }

    .user_input_detail {
        margin-bottom: 0px;

        .error {
            display: none;
        }

        .user_label {
            display: $d_none;
        }

        .serach_input {
            >input {
                width: $w_100;
                padding: 9px 36px 10px !important;
                border-radius: $radius_20 !important;
                border: unset;
                font-size: $f14 !important;
                line-height: $lineheight_20 !important;
                letter-spacing: $letter_02 !important;
                font-family: "FiraSansCondensed-Regular", sans-serif !important;
                color: $text_black !important;

                &::placeholder {
                    color: $search_color !important;
                    font-family: "FiraSansCondensed-Regular", sans-serif;
                    font-weight: $normal;
                    font-size: $f14 !important;
                    text-transform: $text_capitalize;
                }

                &:focus {
                    background-color: $focus_input !important;
                    border-color: $focus_input !important;
                }
            }

        }
    }

    .ant-input-suffix {
        bottom: 6px;

        .anticon {
            svg {
                font-size: 22px;
            }
        }
    }

}

.record_filter {
    .ant-select-selector {
        border-radius: $radius_10 !important;
        padding: 20px 15px !important;
        border-color: unset !important;
        border: unset !important;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: unset !important;
        box-shadow: unset !important;
        border: unset !important;
    }
}

.download {
    margin: 0 20px;
    padding: 10px 15px;
    color: $white;
    font-size: $f14;
    line-height: $lineheight_20;
    font-weight: $medium;
    font-family: 'Poppins-Medium', sans-serif;
    text-transform: $text_capitalize;
    letter-spacing: $letter3125;
    text-align: $text_left !important;
    background: $maroon;
    border: transparent;
    border-radius: $radius_10;

    @media (max-width:1575px) {
        margin: 10px 20px;

    }

}

.user_list {
    .user_panel {
        @media (max-width:1108px) {
            flex-wrap: $flex_wrap;
            justify-content: unset !important;

        }

        .add_user {
            display: $d_none !important;
        }
    }
    
    .user_table {
        .user_table_main {
            tbody {
                tr {
                    td {
                        &:nth-child(1) {
                            color: unset
                        }
                    }
                }
            }
        }
    }
}

.advanced_setting {
    .collapsible_button {
        .collapsible {
            .content {
                >.add_input_show {
                    overflow-y: auto;
                }
            }
        }
    }
}