.file_deletion {


    .modal_main {
        display: flex;
        gap: 10px;
        margin-left: 10px;

        .user_select_box {
            position: $position_relative;
            max-width: $w_201;
            display: $d_block;
            appearance: none;
            width: $w_100;
            background: $dark_white;
            border: 1.5px solid $light_gray;
            border-radius: $radius_10;
            padding: 9.5px 10px;
            color: #656565;
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: $letter3125;
            margin: 1.5px 0 0;
            font-family: 'Poppins-Regular', sans-serif;

            @media (max-width: $large) {
                max-width: $w_100;
            }

            &::before {
                content: '';
                position: $position_absolute;
                right: 7px;
                background: url('../image/down_arrow.svg'), $background-norepeat;
                width: 16px;
                height: 9.37px;
                bottom: 15px;
                pointer-events: none;
            }

            .ant-select {
                width: 100%;
            }

            .ant-select-arrow {
                display: block;
            }

            .ant-select-selector {
                padding: 0px !important;
                height: 20px !important;
                position: relative;
                background-color: unset !important;
                border: unset !important;
                border-radius: 0px !important;
                transition: unset !important;
                align-items: $align_items_center;
                box-shadow: unset !important;
            }

            .anticon-down {
                display: $d_none;
            }
        }
    }

    .user_table {
        .user_table_main {
            thead {
                tr {
                    th:has(:not(:first-child)) {
                        &::before {
                            content: "";
                            position: absolute;
                            right: 0;
                            width: 0 !important;
                            height: 0 !important;
                            background: transparent !important;
                            padding: 0;
                            z-index: unset;
                        }
                    }

                    :nth-last-child(2) {
                        &::before {
                            content: "";
                            position: absolute;
                            right: 0;
                            width: 0 !important;
                            height: 0 !important;
                            background: transparent !important;
                            padding: 0;
                            z-index: unset;
                        }
                    }
                }
            }
        }
    }
}