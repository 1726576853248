.files-list-container_Delete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0 22px 0;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;

    &:hover {
      background: #555;
    }
  }
}

.file-item_file_deletion {
  // padding: 6px 20px;
  display: flex;
  align-items: center;
  gap: 8px;

  // &:first-child {
  //   // padding-top: 10px;
  // }

  // &:last-child {
  //   // border-bottom: none;
  // }

  .bullet_Delettions {
    color: #666;
    font-size: 16px;
    line-height: 1;
    min-width: 12px;
  }

  p {
    color: #666 !important;
    font-size: 14px !important;
    margin: 0 !important;
    line-height: 1.5 !important;
  }
}

// .delete_notification {
//   .modal {
//     min-width: 400px;
//     max-width: 600px;
//   }

//   .modal_title {
//     p {
//       margin-bottom: 10px
//     }
//   }

//   .modal_desc {
//     padding: 0px 0 0px !important;
//     max-width: 346px;
//     width: 100%;
//     margin: 0 auto;

//     p {
//       color: #666 !important;
//       font-size: 14px !important;
//       margin: 0 !important;
//       line-height: 1.5 !important;
//     }
//   }

//   // .separator-line {
//   //   height: 1px;
//   //   background-color: #eee;
//   //   margin: 15px 0;
//   //   width: 100%;
//   // }

.modal_proceed-text_Filedeletion {
  padding: 0 0 32px 0;

  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(37, 40, 44, 0.8);


  }
}

//   // .modal_info-text {
//   //   margin-top: 10px;
//   // }

//   .notification_btn {
//     margin-top: 15px;
//     display: flex;
//     justify-content: center;
//     gap: 10px;
//   }
// }