// Default-CSS
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

input[type='file']::-moz-file-upload-button {
  cursor: pointer;
}

input[type='file']::-ms-file-upload-button {
  cursor: pointer;
}

input[type='file']::-o-file-upload-button {
  cursor: pointer;
}

button {
  cursor: pointer;
}

input[type='file'] {
  cursor: pointer;
}

html,
body {
  margin: 0;
  overflow-x: visible;
  font-family: 'Poppins-Regular', sans-serif;
}

*::-moz-selection {
  color: $white;
  background: $black;
}

*::-webkit-selection {
  color: $white;
  background: $black;
}

*::-webkit-input-placeholder {
  color: $gray_color;
  opacity: 1;
}

*:-moz-placeholder {
  color: $gray_color;
  opacity: 1;
}

*::-moz-placeholder {
  color: $gray_color;
  opacity: 1;
}

*:-ms-input-placeholder {
  color: $gray_color;
  opacity: 1;
}

a,
div a:hover,
div a:active,
div a:focus,
button {
  text-decoration: none;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

a,
span,
div a:hover,
div a:active,
button {
  text-decoration: none;
}

*::after,
*::before,
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  width: $w_100;
  height: auto;
}

body a {
  outline: none;
  cursor: pointer;
}

body a:hover {
  color: $black;
}

body .clearfix,
body .clear {
  clear: both;
  line-height: 100%;
}

body .clearfix {
  height: auto;
}

* {
  outline: none !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clr:after,
ul:after,
.clearfix:after,
li:after,
.grve-container:after {
  clear: both;
  display: block;
  content: '';
}

div input,
div select,
div textarea,
div button {
  font-family: 'Poppins-Regular', sans-serif;
}

div select {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

div select option {
  font-size: $f13;
  padding: 2px 5px;
}

img {
  max-width: $w_100;
  max-height: $h_100;
  width: auto;
  height: auto;
  display: $d_block;
}

body p:empty {
  margin: 0;
  line-height: 0;
}

body p:last-child {
  margin-bottom: 0;
}

p strong {
  font-weight: $extra_bold;
}

.hidden {
  display: none !important;
}

iframe {
  display: block;
  width: 100%;
}

.container:after {
  content: '';
  display: block;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: 'Poppins-Bold', sans-serif;
}

.container {
  padding: 0 130px 0 90px;
  width: $w_100;

  @media (max-width: 1200px) {
    @include container;
  }
}

p {
  margin: 0;
  padding: 0;
}

input:focus {
  outline: none;
}

//button
.clear {
  border-radius: $radius_10;
  background: $clear;
  border: transparent;
  width: $w_100;
  max-width: $w_154;
  padding: 16px 57px;
  font-size: $f14;
  line-height: $lineheight_20 !important;
  letter-spacing: $letter3125;
  color: $black;
  font-family: 'Poppins-Regular', sans-serif;
  text-transform: $text_capitalize;
  max-width: max-content;

  @media (max-width: $mobile) {
    max-width: $w_267;
  }
}

.primary {
  @extend .clear;
  background: $primary;
  color: $white;
  max-width: $w_267;
  margin-left: 16px;
  text-align: center;

  @media (max-width: $mobile) {
    margin: 20px 0 0;
  }
}

.ant-form-item-explain-error {
  color: $error;
  font-size: $f14;
  line-height: $lineheight_20;
  letter-spacing: $letter3125;
  font-family: 'Poppins-Regular', sans-serif;
  text-transform: $text_capitalize;
  padding: 5px 0 0;
}

.filter-block {
  display: flex;
  align-items: baseline;

  &_title {
    margin-right: 10px;
  }

  &_icons {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .clear_icon_filter {
      height: 20px;
      width: 20px;
    }
  }
}

.mr_10 {
  margin-right: 10px;
}

.w_10 {
  width: 10px;
}

.cursor_pointer {
  cursor: pointer;
}

.loader {
  position: absolute;
  // background: #fff;
  top: 0;
  margin-top: 200px;
  width: 100vw;
  padding: 3rem 5rem;
  text-align: center;
  border-radius: 0.4rem;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: #ff7875;
      }
    }
  }
}

.btn-loader {
  height: 20px;

  .ant-spin {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: #ff7875;
      }
    }
  }
}

.table-loader {
  background: #fff;
  top: 45px !important;

  .ant-spin-dot {
    .ant-spin-dot-item {
      background-color: #ff7875;
    }
  }
}

.full-content-loader {
  position: absolute;
  background: #fff;
  width: 100vw;
  padding: 3rem 5rem;
  text-align: center;
  border-radius: 0.4rem;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .ant-spin {
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: #ff7875;
      }
    }
  }
}

//tooltip

.tooltip {
  display: $d_inline_block;
  position: $position_relative;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

.tooltiptext {
  visibility: hidden;
  min-width: max-content;
  position: $position_absolute;
  left: 0;
  z-index: 1;
  background: $gray;
  border-radius: $radius_5;
  font-size: $f12;
  line-height: 14px;
  font-weight: $medium;
  letter-spacing: 0.3125px;
  color: $white;
  padding: 5px 7px;
  top: -30px;
  margin: 0 !important;
  text-transform: $text_capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-height: 19px;

  &::before {
    content: '';
    position: $position_absolute;
    background: url('../image/tooltip.svg'), $background-norepeat;
    bottom: -3px;
    left: 6px;
    width: 6px;
    height: 6px;
  }
}

.disable_icon {
  opacity: 0.5;
}

.disabled {
  pointer-events: none;
  filter: grayscale(10);
  opacity: 0.4;
}

.upload_note {
  color: red;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  display: block;
  margin: 0 0 15px;
}

.rencoa_module {
  span {
    display: $d_none;
  }

  .select_clean {
    margin-left: 0px !important;
  }
}

.modal_main {

  // position: $position_relative;
  &.active {
    // .modal{
    //     position: fixed;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     z-index: 1000;
    //     height: 100%;
    //     // background-color: rgba(0, 0, 0, 0.45);
    //     max-width: unset;
    //     .modal_detail{
    //         position: relative;
    //         background-color: #fff;
    //         background-clip: padding-box;
    //         border: 0;
    //         border-radius: 10px;
    //         pointer-events: auto;
    //         max-width: 293px;
    //         width: 100%;
    //         margin: 0 auto;
    //         z-index: 100;
    //         box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    //         top:230px;
    //     }

    // }
  }
}

.access-filter-pop {
  .ant-tooltip-content {
    height: 100%;
    width: 100%;
    min-width: 293px;
    min-height: 203px;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      background-color: #fff;
      background-clip: padding-box;
      border: 0;
      border-radius: 10px;
      pointer-events: auto;
      max-width: 293px;
      width: 100%;
      margin: -14px auto;
      //z-index: 100;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    }
  }
}

.filter-block_icons {
  .w_10 {
    padding-left: 10px;
  }
}

.ant-select-arrow svg {
  display: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.error-message {
  color: red;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  font-family: 'Poppins-Regular', sans-serif;
  text-transform: capitalize;
  padding: 5px 0 0;
  font-weight: 500;
}


html {
  .ant-tooltip-inner {
    background: $gray;
    border-radius: $radius_5;
    font-size: $f12;
    line-height: 14px;
    font-weight: $medium;
    letter-spacing: 0.3125px;
    color: $white;
    padding: 5px 7px;
    top: -30px;
    margin: 0 !important;
    text-transform: $text_capitalize;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    min-height: 19px;
    box-shadow: unset;
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: $gray;
    background: $gray;
  }

  // right: 0;
  //  transform: translateY(100%) translateX(0); 
  //  left: unset;
  .action_icon .status_button button:last-child {
    margin-right: 50px !important;
  }
}


.descend {
  transform: rotate(180deg);
  cursor: pointer;

  &.active {
    path {
      fill: $light_blue;
    }
  }
}

.ascend {
  cursor: pointer;

  &.active {
    path {
      fill: $light_blue;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel {
    display: $d_block;

    .ant-picker-year-panel {
      .ant-picker-header {}

      .ant-picker-body {
        padding: 0px 0px 29px;

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
          margin: 0 auto;
        }
      }

      margin: 0 auto;
    }
  }
}

.ant-picker-decade-panel {
  margin: 0 auto;

  .ant-picker-content {
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        width: 100%;
      }
    }
  }

  .ant-picker-body {
    padding-bottom: 10px;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel {
    .ant-picker-month-panel {
      margin: 0 auto;
    }

    .ant-picker-header {
      padding: 15px 37px 0px;
      border: 0px;
    }

    .ant-picker-date-panel .ant-picker-body {
      padding: 22px 37px 15px;
    }

    .ant-picker-year-panel {
      .ant-picker-body {
        padding-bottom: 10px;
      }
    }
  }

}


.ant-table-body {
  max-height: var(--table-body-height, unset) !important;
  overflow-y: auto !important;
}