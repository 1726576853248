.sidebar_panel {
    width: 18.8rem;
    min-height: calc(100vh - 24.6rem);

    .user_panel {
        li {
            padding: 27px 0px;
            position: $position_relative;
            cursor: pointer;

            a {
                color: $black;
                font-weight: $bold;
                font-size: 16px;
                line-height: $lineheight_20;
                letter-spacing: 0.05em;
                text-transform: $text_uppercase;
                padding: 0px 37px;
            }

            &:hover {
                background: $maroon;

                a {
                    color: $white;
                }
            }

            &.active {
                background-color: $maroon;

                a {
                    color: $white;
                }
            }
        }

        .sub_menu {
            li {
                padding: 26.5px 57px;
                background: #ABC9D9;

                &.active {
                    background: #76919F;

                    a {
                        font-weight: $bold;
                        padding: 0 0;
                    }
                }

                a {
                    font-weight: $normal;
                    padding: 0 0;
                    color: $white;
                }

                &:hover {
                    background: #76919F;

                    a {
                        font-weight: $bold;
                        padding: 0 0;
                    }
                }
            }
        }
    }

    .traingle {
        position: $position_absolute;
        right: 28px;
        top: 33.33px;
        transition: 0.2s all;


        &.active {
            transform: rotate(180deg);
        }
    }
}

.traingle {
    position: $position_absolute;
    right: 28px;
    top: 33.33px;
    transition: 0.2s all;


    &.active {
        transform: rotate(180deg);
    }
}