.user_table {
  width: $w_100;
  overflow: auto;

  .user_table_main {
    width: $w_100;
    overflow: auto;
    position: $position_relative;

    thead {
      background: $maroon;
      width: $w_100;

      tr {
        th {
          color: $white;
          font-size: $f14;
          line-height: 32px;
          font-weight: $medium;
          font-family: 'Poppins-Medium', sans-serif;
          text-transform: $text_capitalize;
          letter-spacing: 0.3125px;
          text-align: $text_left !important;
          position: $position_relative;
          min-width: 220px;
          padding: 13px 11px 10px 11px;
          width: 220px;
          background: $maroon;

          .ant-table-filter-column {
            justify-content: unset;
            width: fit-content;

            .ant-table-column-title {
              margin-right: 10px;
            }

            .ant-dropdown-trigger {
              margin: 0;
              padding: 0;
            }
          }

          .access {
            display: $d_flex;
            align-items: $align_items_baseline;

            span {
              margin: 0 0 0 10px;
              cursor: pointer;
            }
          }

          &::before {
            content: '';
            position: $position_absolute;
            right: 0;
            width: 0.5px !important;
            height: 21px;
            background: rgba(217, 217, 217, 0.9) !important;
            padding: 13px 0 10px;
            z-index: 9;
          }

          &:nth-child(1) {
            padding: 13px 0px 10px 48px;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba(138, 3, 5, 0.1);

        &:last-child {
          border-bottom: unset;
        }

        td {
          color: $dark_text_black;
          font-size: 13px;
          line-height: $lineheight_20;
          letter-spacing: $letter_02;
          font-weight: $medium;
          font-family: 'Poppins-Medium', sans-serif;
          background: $white !important;
          padding: 26px 11px 26px 11px;
          text-align: $text_left !important;


          &:nth-child(1) {
            padding: 26px 0px 26px 48px;
            color: $emali_color_text;
          }

          .lms_client_noca_name {
            display: block;
            font-size: 11px;
            line-height: 14px;
          }
        }

        .action_icon {
          display: $d_flex;
          justify-content: $justify_content_between;

          .notes {
            button {
              background: transparent;
              border: none;
              color: #1784e9;
              padding: 0;
              margin: 0;
            }

            button[disabled] {
              color: #d6d6d6;
            }
          }

          .downloads_btn {
            padding: 0;
            border: none;

            &:hover {
              background: #2e75b6 !important;
            }

            &:focus {
              background: #2e75b6 !important;
            }

            &:disabled {
              background: #DEDEDE !important;
            }
          }

          span {
            margin: 0 4px;
            cursor: pointer;

            &:nth-child(1) {
              margin: 0 5px 0 0;
            }

            &:last-child {
              margin: 0 0 0 5px;
            }
          }
        }
      }
    }
  }
}

.ant-table-cell-scrollbar {
  box-shadow: unset !important;
}

.ant-table-cell-scrollbar::before {
  content: unset !important;
}

.action {
  &::before {
    content: unset !important;
  }
}

.user_table {

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 20px 1px 20px 20px #dedede;
    border-radius: 0px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #636363;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #636363;
  }
}

.ant-table {
  margin-bottom: 41px;
}

.user_table {
  .client_table {
    tbody {
      tr {
        .ant-table-cell {
          padding: 27px 0px 27px 11px !important;

          &:nth-child(1) {
            padding: 27px 0px 27px 48px !important;
          }
        }

        td {
          .delete_icon_del_btn {
            padding: 0;
            height: auto;
            border: 0;
          }
        }
      }
    }
  }
}

//pagination

.ant-table-pagination {
  justify-content: unset;
  background: #d9d9d9;
  border-radius: 30px;
  margin: 0 auto 47px !important;
  padding: 14px;
  align-items: $align_items_center;
  position: $position_relative;
}

.ant-pagination-prev,
.ant-pagination-next {
  background: $white;
  width: 36px;
  height: 36px;
  display: $d_flex;
  align-items: $align_items_center;
  justify-content: $justify_content_center;
  border-radius: $radius_50;
  margin-right: 21px;
}

.ant-pagination-next {
  margin-left: 21px;
  margin-right: 0px;

  svg {
    transform: rotate(180deg);
  }
}

.ant-pagination-item-active {
  border: none;

  a {
    color: $maroon !important;
  }
}

.ant-pagination-item:hover {
  border: none;

  a {
    color: $maroon !important;
  }
}

.ant-pagination-item {
  background: unset;
  height: unset;
  margin-right: 14px;
}

.ant-pagination-item a {
  font-weight: $bold;
  font-size: 20px;
  line-height: $lineheight_20;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Poppins-Medium', sans-serif;
  padding: 0px;
}

.ant-spin-container {
  display: $d_flex;
  flex-direction: column;
  justify-content: space-between;
}

//user list table

.user_list_table {
  table {
    thead {
      tr {
        .ant-table-cell {
          &:nth-last-child(2) {
            &::before {
              content: unset !important;
            }
          }
        }
      }
    }
  }
}

//pagination

.ant-pagination-options {
  display: $d_none;
}

.notes {

  .ant-btn[disabled],
  .ant-btn[disabled]:active,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    background: transparent !important;
  }
}