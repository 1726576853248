.sign_in_main {
    background: url('../image/signin_bg.webp'), $background-norepeat;
    background-size: $background_size_cover;
    width: $w_100;
    height: $h_100;
    padding: 144px 0 278px 37px;
    //background-position: center center;

    @media (max-width:$ipad) {
        padding: 144px 0 278px 0px;

    }


    .user_form {
        max-width: $w_554;
        width: $w_100;
        background: $white;
        box-shadow: $form_box-shadow;
        border-radius: $radius_6;
        overflow: hidden;

        .user_title {
            background: $maroon;
            text-align: $text_center;

            h3 {
                color: #fff;
                font-size: $f24;
                line-height: $lineheight_40;
                text-transform: $text_uppercase;
                letter-spacing: $letter2;
                padding: 16px 0;

                @media (max-width:$mobileLarge) {
                    font-size: $f18;
                    line-height: $lineheight_20;

                }

            }
        }

        .user_detail {
            width: $w_100;
            padding: 0px 38px 0px 64px;

            @media (max-width:$mobileLarge) {
                padding: 0px 20px 0px 20px;
            }

            .user_sign_in_title {
                padding: 32px 0px;

                h2 {
                    font-size: $f28;
                    line-height: $lineheight_40;
                    letter-spacing: $letter7;
                    color: $black_dark;

                    @media (max-width:$mobileLarge) {
                        font-size: $f18;
                        line-height: $lineheight_20;


                    }
                }

                .sign_in_text {
                    color: $dark_gray;
                    letter-spacing: $letter3125;
                    line-height: $lineheight_22;
                    font-size: $f16;
                    font-weight: $normal;

                }

                .contact_info {
                    color: $maroon;
                    font-size: $f14;
                    line-height: $lineheight_22;
                    letter-spacing: $letter3125;
                    font-weight: $semi_bold;
                    padding-top: 3px;

                    .support {
                        color: $maroon;
                        text-decoration: underline;
                    }
                }


            }
        }

        .form_detail {
            // padding: 0 15px 0 0;
            padding: 0px 15px 86px 0px;

            @media (max-width:$mobileLarge) {
                padding: 0px 0px 86px 0px;
            }

            .forgot_password_link {
                text-align: $text_right;
                padding: 0 16px 28px 0;

                a {
                    font-size: $f14;
                    line-height: $lineheight_20;
                    letter-spacing: $letter3125;
                    font-family: "Poppins-Regular", sans-serif;
                    color: $dark_text_black;
                    text-transform: $text_capitalize;

                }

            }


        }

    }
}

.user_input_detail {
    // margin-bottom: 16px;
    position: relative;

    .ant-input {
        width: $w_100;
        background: $dark_white  !important;
        border: 1.5px solid $light_gray  !important;
        border-radius: $radius_10  !important;
        padding: 9.5px 10px !important;
        color: $text_black  !important;
        font-size: $f14  !important;
        line-height: $lineheight_20  !important;
        letter-spacing: $letter3125  !important;
        font-family: "Poppins-Regular", sans-serif !important;
        margin: 3px 0 0;

        &::placeholder {
            color: #C4C4C4 !important;
            font-weight: $normal  !important;
            text-transform: $text_capitalize;
        }

        &:focus {
            background-color: $focus_input  !important;
            color: $text_black  !important;
            font-weight: $normal  !important;
            border-color: none !important;
            box-shadow: none !important;
        }

    }


}

.user_label {
    width: $w_100;
    color: $dark_gray;
    font-size: $f14;
    line-height: $lineheight_20;
    letter-spacing: $letter3125;
    font-family: "Poppins-Regular", sans-serif;
    font-weight: $semi_bold;
    text-transform: $text_capitalize;
}

.ant-input-suffix {
    position: $position_absolute;
    bottom: 13px;
    right: 15px;
    border: none;
    background: none;
    cursor: pointer;

}

.ant-input-affix-wrapper {
    padding: unset !important;
    color: unset !important;
    background-color: unset !important;
    background-image: unset !important;
    border: unset !important;
    border-radius: unset !important;
}

.ant-form-item {
    margin-bottom: 0px;
}

.ant-form-item-control {
    margin-bottom: 16px;
}

.ant-input-affix-wrapper-focused {
    box-shadow: unset !important;
}

.user_button {
    display: $d_flex;

    @media (max-width:$mobile) {
        flex-wrap: $flex_wrap;
        justify-content: $justify_content_center;
    }

    .password_show_hide {
        position: $position_absolute;
        bottom: 13px;
        right: 15px;
        border: none;
        background: none;

    }
}

.reset_password {
    .form_detail {
        padding: 0px 15px 71px 0px !important;
    }

    .user_button {
        padding: 15px 0 0;
    }
}