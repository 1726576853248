//poppins font 

@font-face {
    font-family: "Poppins-Bold";
    src: url(../font/Poppins-Bold.woff);
    font-weight: $bold;
}

@font-face {
    font-family: "Poppins-Black";
    src: url(../font/Poppins-Black.woff);
    font-weight: $font_black;
}

@font-face {
    font-family: "Poppins-Medium";
    src: url(../font/Poppins-Medium.woff);
    font-weight: $medium;
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url(../font/Poppins-ExtraBold.woff);
    font-weight: $extra_bold;
}

@font-face {
    font-family: "Poppins-Regular";
    src: url(../font/Poppins-Regular.woff);
    font-weight: $normal;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(../font/Poppins-SemiBold.woff);
    font-weight: $semi_bold;
}

@font-face {
    font-family: "Poppins-Thin";
    src: url(../font/Poppins-Thin.woff);
}

@font-face {
    font-family: "Poppins-Light";
    src: url(../font/Poppins-Light.woff);
    font-weight: $extra_light;
}

//firasans font
@font-face {
    font-family: "FiraSans-Regular";
    src: url(../font/FiraSans-Regular.ttf);
    font-weight: $normal;
}

//GothamMedium font
@font-face {
    font-family: "GothamMedium";
    src: url(../font/GothamMedium.woff);
    font-weight: $normal;
}


//firasans font
@font-face {
    font-family: "Raleway-Regular";
    src: url(../font/Raleway-Regular.woff);
    font-weight: $normal;
}