.modal_active {
  overflow: hidden;
}

.modal_main {
  width: $w_100;
  height: $h_100;

  &.active {
    visibility: visible;
    opacity: 1;

    .modal {
      visibility: visible;
      opacity: 1;
    }
  }

  .modal {
    .modal_from {
      max-width: $w_437;
      width: 100%;
      margin: $m_auto;
      padding: 46px 0px 56px;

      @media (max-width: $mobileLarge) {
        padding: 46px 30px 56px;
      }

      .user_input_detail {
        margin-bottom: 24px;
        position: $position_relative;

        &::before {
          content: '';
          position: $position_absolute;
          right: -17px;
          width: 8px;
          height: 8px;
          background: $input_round;
          border-radius: $radius_50;
          border: transparent;
          bottom: 17px;
        }

        &.active {
          &::before {
            background: green;
          }
        }

        .error {
          display: $d_none;
        }

        .user_input {
          color: #656565;
          margin: 1.5px 0 0;
        }
      }

      .ant-select {
        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }

      .modal_name {
        display: $d_flex;

        @media (max-width: $large) {
          flex-wrap: $flex_wrap;
        }

        .ant-form-item {
          max-width: $w_201;
          width: 100%;

          @media (max-width: $large) {
            max-width: $w_100;
          }

          &:first-child {
            margin-right: 35px;

            @media (max-width: $large) {
              margin-right: 0px;
            }
          }
        }
      }

      .ant-select-focused {
        background-color: $focus_input !important;
        border-color: $focus_input !important;

        .ant-select-selector {
          box-shadow: none !important;
        }
      }

      .user_select {
        .ant-form-item-row {
          display: flex;
          flex-direction: column;

          .ant-form-item-label {
            width: fit-content;
            color: #595959;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            font-family: 'Poppins-Regular', sans-serif;
            font-weight: 600;
            text-transform: capitalize;

            label {
              &::after {
                content: '';
              }

              &::before {
                content: '';
              }
            }
          }
        }
      }

      .user_select_box {
        position: $position_relative;
        max-width: $w_201;
        width: $w_100;
        display: $d_block;
        appearance: none;
        width: $w_100;
        background: $dark_white;
        border: 1.5px solid $light_gray;
        border-radius: $radius_10;
        padding: 9.5px 10px;
        color: #656565;
        font-size: $f14;
        line-height: $lineheight_20;
        letter-spacing: $letter3125;
        margin: 1.5px 0 0;
        font-family: 'Poppins-Regular', sans-serif;

        @media (max-width: $large) {
          max-width: $w_100;
        }

        &::before {
          content: '';
          position: $position_absolute;
          right: 7px;
          background: url('../image/down_arrow.svg'), $background-norepeat;
          width: 16px;
          height: 9.37px;
          bottom: 15px;
          pointer-events: none;
        }

        .ant-select-selector {
          padding: 0px !important;
          height: 20px !important;
          position: relative;
          background-color: unset !important;
          border: unset !important;
          border-radius: 0px !important;
          transition: unset !important;
          align-items: $align_items_center;
          box-shadow: unset !important;
        }

        .anticon-down {
          display: $d_none;
        }
      }
    }
  }
}

.user_checkbox {
  margin: 0 0 32px;

  ul {
    display: $d_flex;
    align-items: baseline;

    li {
      margin: 0 20px 0 0;

      @media (max-width: $large) {
        margin: 0 20px 0 0;
      }
    }
  }

  &_error {
    color: red;
  }
}

.checkbox_filter {
  padding: 18px 6px 36px;

  .user_checkbox {
    margin: 0 0;

    ul {
      display: block;

      li {
        margin: 0 0 12px 0px;

        &:last-child {
          margin: 0 0 0px 0px;
        }
      }
    }

    .checkbox {
      &+label {
        &::before {
          border-radius: 0px;
        }
      }
    }
  }
}

.custom-modal {
  margin: $m_auto;
  max-width: $w_696;
  width: $w_100 !important;
  height: auto;
  z-index: 100;
  border-radius: $radius_10;
  overflow: hidden;
  filter: drop-shadow(4px 4px 50px rgba(0, 0, 0, 0.15));
  padding-bottom: 0px;

  @media (max-width: $large) {
    overflow: auto;
    position: $position_relative;
    max-width: 90%;
    transform: none;
  }

  .ant-modal-body {
    padding: 0px;
    font-size: 0px;
    line-height: 0;
    word-wrap: break-word;
  }
}

.add_user {
  .user_input_detail {
    margin-bottom: 0px !important;
  }

  .ant-form-item-control {
    margin-bottom: 24px;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 0px !important;
}

.ant-select-dropdown {
  border-radius: $radius_10;
}

.change_password {
  .user_input_detail {
    margin-bottom: 0px !important;
  }

  .user_button {
    padding: 25px 0 0;
  }
}

.ant-checkbox-wrapper {
  align-items: center;
}

.ant-checkbox-input {
  position: $position_absolute;
  opacity: 0;
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: unset !important;
  }

  &::after {
    content: '';
    position: absolute;
    left: 4px;
    right: 4px;
    top: 3.5px;
    width: 11px;
    height: 11px;
    background-image: url(../image/right.svg);
    background-repeat: no-repeat;
    background-size: 10px 10px;
    visibility: visible;
    border: unset;
    animation: unset !important;
    z-index: 9;
  }
}

.ant-checkbox-inner {
  margin-right: 12px;
  display: $d_inline_block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background: $white_bg;
  border: 1px solid $checkbox_color !important;
  border-radius: 0px !important;
}

.ant-checkbox-inner {
  &::after {
    content: unset !important;
  }
}

.modal_title {
  p {
    font-size: $f14;
    text-align: $text_center;
    line-height: $lineheight_21;
    text-transform: $text_capitalize;
    font-weight: $semi_bold;
  }
}

.modal_detail {
  .modal_close {
    padding: 14px 18px 0 0;
    display: $d_flex;
    justify-content: $justify_content_end;
    cursor: pointer;
  }

  &_file-listing {
    height: 100%;
    max-height: 400px;

    &_list {
      width: 100%;
      background: #000;
      color: #fff;

      p {
        font-size: 16px;
      }
    }
  }
}

.modal_date {
  font-size: $f12;
  text-align: $text_center;
  line-height: 18px;
  font-weight: 400;
  color: rgba(37, 40, 44, 0.5);
}

.ant-checkbox {
  top: 0px !important;
}

.table-modal {
  max-width: 1468px;
  max-height: 1170px;

  @media (max-width:1300px) {
    max-width: 90%;

  }

  // .ant-table-body {
  //   max-height: 470px !important;

  // }



  &_container {
    &_header {
      height: 100%;
      padding: 31px 0 20px 0;
      color: #000;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h3 {
        font-size: 14px;
        line-height: 21px;
        text-transform: capitalize;
        font-weight: 600;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

// .ant-select-dropdown{
//   left: 38% !important;
//   top: 448px !important;
// }

// .ant-table-body {
//   max-height: unset !important;
//   overflow-y: hidden !important;

// }

//! Download_PopUP Start 

.download_radio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download_all_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}