.moblie_panel {
    position: $position_fixed;
    display: $d_block;
    padding-left: 0;
    top: 0;
    left: 0;
    max-width: 400px;
    width: 100%;
    transform: translateX(-100%);
    z-index: 999;
    height: 100%;
    transition: 0.4s ease;
    background: $white;

    &.active {
        transform: translateX(0%);
    }

    .sidebar_panel {
        width: 100%;
        overflow: hidden;
        display: $d_block;

        .modal_close {
            padding: 25px 25px;
            display: $d_flex;
            justify-content: $justify_content_end;
            cursor: pointer;
        }

        .header_admin {
            display: $d_flex;
            padding: 0 25px 25px;
        }
    }

}