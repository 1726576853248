@mixin width($maxWidth) {
    max-width: $maxWidth;
    width: $w_100;
}

@mixin container {
    @include width(1680px);
    margin: $m_auto;
    padding: 0 20px;
}


  // custom devices
  @mixin customMedia($custom) {
    @media (max-width: $custom+'px') {
      @content;
    }
  }
  // Small smartphones
  @mixin media350 {
    @media (max-width: #{$screen-350}) {
      @content;
    }
  }
  // Small devices
  @mixin media480 {
    @media (max-width: #{$screen-480}) {
      @content;
    }
  }
  @mixin media675 {
    @media (max-width: #{$screen-675}) {
      @content;
    }
  }
  // Small smartphones
  @mixin media575 {
    @media (max-width: #{$screen-575}) {
      @content;
    }
  }
  // Medium devices
  
  @mixin media767 {
    @media (max-width: #{$screen-767}) {
      @content;
    }
  }
  // tablet devices
  @mixin media991 {
    @media (max-width: #{$screen-991}) {
        @content;
    }
  }
  // large devices
  @mixin media1199 {
    @media (max-width: #{$screen-1199}) {
        @content;
    }
  }
  // Extra large devices
  @mixin media1366 {
    @media (max-width: #{$screen-1366}) {
        @content;
    }
  }
  // extra Large tablets and desktops
  @mixin media1429 {
    @media (max-width: #{$screen-1429}) {
        @content;
    }
  }
  // Extra large devices
  @mixin media1599 {
    @media (max-width: #{$screen-1599}) {
        @content;
    }
  }