.footer {
    background: $footer_bg;
    padding: 84px 0 40px;

    .footer_main {
        display: $d_flex;
        justify-content: $justify_content_between;

        @media (max-width:$mobileLarge) {
            flex-wrap: $flex_wrap;

        }

        @media (max-width:$mobileSmall) {
            justify-content: $justify_content_center;


        }

        .footer_logo {
            max-width: 155px;
            width: 100%;

            @media (max-width:$ipad) {
                padding: 0px 0 20px;
            }

            @media (max-width:$mobileLarge) {
                width: $w_50;

            }

            @media (max-width:$mobileSmall) {
                width: $w_100;

                img {
                    margin: $m_auto;
                }

            }


        }



        .footer_contact {
            @media (max-width:$mobileLarge) {
                width: $w_50;

            }

            @media (max-width:$mobileSmall) {
                width: $w_100;


            }


            .contact_title {
                font-size: $f14;
                line-height: $lineheight_20;
                font-weight: $bold;
                color: $white;
                letter-spacing: $letter_02;
                text-transform: $text_capitalize;
                margin-bottom: 14px;
                text-align: $text_right;

                @media (max-width:$mobileSmall) {
                    text-align: $text_center;

                }
            }

            .contact_number {
                p {
                    color: $light_gray_place;
                    font-size: $f16;
                    line-height: $lineheight_20;
                    letter-spacing: $letter_02;
                    text-transform: $text_capitalize;
                    font-weight: $bold;
                    text-align: $text_right;

                    @media (max-width:$mobileSmall) {
                        text-align: $text_center;

                    }

                }

            }

            .emali_text {
                color: $light_gray_place;
                font-size: $f16;
                line-height: $lineheight_20;
                letter-spacing: $letter_02;
                font-weight: $bold;
                padding-top: 5px;
                text-align: $text_right;

                @media (max-width:$mobileSmall) {
                    text-align: $text_center;

                }
            }
        }

    }

    .footer_copy_right {
        display: $d_block;
        text-align: $text_center;

        @media (max-width:$mobileLarge) {
            width: $w_100;
            justify-content: $justify_content_center;
            order: 3;
            text-align: $text_center;

        }

        p {
            font-family: "Poppins-Regular", sans-serif;
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: $letter_02;
            text-transform: $text_capitalize;
            color: $light_gray_place;
            position: $position_relative;
            display: $d_inline_block;
            margin-top: 20px;
            padding: 0 0 0 22px;

            @media (max-width:$ipad) {
                font-size: $f11;

            }



            &::before {
                content: '';
                position: $position_absolute;
                left: 0;
                top: 0;
                background: url('../image/copyright.svg'), $background-norepeat;
                width: $w_16px;
                height: $h_17;

            }
        }
    }
}