.user_disabled {
    max-width: 431px;
    width: $w_100;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    @media (max-width:991px) {
        max-width: 90%;

    }

    .modal_detail {
        padding: 22px 0 38px;

        @media (max-width:$large) {
            padding: 22px 20px 38px;
        }
    }





}

.filter_modal {
    .modal {
        max-width: 293px;

        .modal_detail {
            padding: 22px 26px 26px;

            .modal_title {
                p {
                    font-weight: $medium;
                    color: rgba(37, 40, 44, 0.8);
                }
            }

            .checkbox_filter {
                padding: 18px 0px 36px;
            }


        }
    }
}

.add_user_modal {
    .modal {

        .modal_detail {
            padding: 0 0 47px;

            .modal_title {
                padding: 7px 0 0px;
            }

            .modal_desc {
                padding: 30px 0 34px;
                max-width: unset;

                span {
                    font-weight: $semi_bold;

                }
            }
        }
    }

}

.delete_notification {
    .modal_desc {
        padding: 27px 0 !important;
    }
}



.notification_btn {
    text-align: $text_center;

    .primary {
        @media (max-width:$mobile) {
            margin: 0 0 0;

        }
    }

    .notification_button {
        max-width: $w_116px;
        margin-left: 0px;
        font-size: $f12;
        line-height: $lineheight_20;
        padding: 6px 33px 6px;
    }

    .notification_clear {
        margin-left: 11px;

        @media (max-width:$mobile) {
            margin: 20px 0 0 0;

        }

    }
}


.ant-checkbox+span {
    padding: 0;
    font-family: 'Poppins-Medium', sans-serif;
    font-weight: $medium;
    font-size: $f14;
    line-height: $lineheight_20;
    letter-spacing: $letter_02;
    color: $dark_text_black;
}

.modal_desc {
    padding: 27px 0 21px;
    max-width: 346px;
    width: 100%;
    margin: $m_auto;


    p {
        font-size: $f12;
        line-height: $lineheight_18;
        color: $text_color;
        text-align: $text_center;
    }
}

.error_modal {
    @extend .user_disabled;

    .modal_desc {
        p {
            font-size: 14px;
        }
    }
}

.ant-dropdown {
    max-width: 293px;
    width: 100%;
    left: 0 !important;
    right: 0 !important;
    // top: 100px !important;
    margin: 0 auto;
    position: relative !important;

}

.ant-table-filter-dropdown {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
}

.add_quote_modal {
    .modal_detail {
        padding: 31px 65px 46px !important;
    }

    .modal_title {
        max-width: 217px;
        margin: 0 auto;
        width: 100%;
    }

    .modal_desc {
        padding: 22px 0 !important;
    }

    .modal_info-text {
        padding: 0 0 22px 0;

        p {
            //font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: rgba(37, 40, 44, 0.8);
        }
    }

    .modal_confirm-text {
        padding: 0 0 32px 0;

        p {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: rgba(37, 40, 44, 0.8);

        }
    }

 


}

.download_radio_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}