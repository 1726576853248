.add_a_jobs_main {
    .add_a_job {
        align-items: unset !important;
        padding: 17px 130px 14px 53px !important;

        @media (max-width:1200px) {
            flex-wrap: $flex_wrap;

        }

        .client_main_panel {
            display: $d_flex;
            align-items: center;

            @media (max-width:$large) {
                flex-wrap: $flex_wrap;

            }

            nav {
                @media (max-width:$large) {
                    margin-bottom: 20px;

                }
            }




            .client_title_new {
                margin-left: 174px;

                @media (max-width:1400px) {
                    margin-left: 60px;

                }

                @media (max-width:962px) {
                    margin: 0px 0 15px 0;
                }
            }
        }

        .client_title_new {
            p {
                font-weight: $bold;
                font-size: $f18;
                line-height: $lineheight_20;
                text-align: $text_center;
                font-family: 'Poppins-Bold', sans-serif;
                text-transform: $text_uppercase;
            }
        }

        .cancel_client_btn {
            @media (max-width:1095px) {
                margin: 10px 0 0;
            }
        }

    }

    .client_history {
        background: $white;

        .client_record {
            padding: 16px 54px;
            display: $d_flex;

            @media (max-width:1213px) {
                flex-wrap: $flex_wrap;

            }
        }

        .title {
            font-size: $f14 !important;
            line-height: $lineheight_20;
            color: $dark_gray !important;
            letter-spacing: 0.01em !important;
            font-weight: $bold;
            padding: 0 0 3px;
            font-family: "Poppins-Bold", sans-serif;
            text-transform: $text_capitalize;
        }

        .client_id_address {
            display: $d_flex;
            padding-bottom: 30px;
            border-bottom: 1px solid #CACACA;

            .title {
                max-width: 155px;
                width: $w_100;
            }
        }

        .client_address {
            // margin-left: 45px;
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: 0.01em;
            font-weight: $normal;

            b {
                font-weight: normal;
                padding-left: 10px;
            }

        }

        .quote_detail {
            padding: 14px 0 10px;

        }

        .last_processed_ncoa {
            margin-left: 44px;
            max-width: 438px;
            width: 100%;
            position: $position_relative;

            @media (max-width:1213px) {
                margin-left: 0px;

            }

            .last_processed_history {
                background: rgba(46, 117, 182, 0.08);
                border-radius: $radius_5;
                padding: 5px 6px 4px 13px;
                margin-bottom: 8px;
            }

            .last_processed_history_sub {
                display: $d_flex;

                .title {
                    width: 65%;
                }

                .client_address {
                    width: 50%;
                    margin-left: 0px;
                }
            }

            .processed_edit_icon {
                position: $position_absolute;
                right: -14%;
                display: inline-flex;
                top: 25px;

                button {
                    background: unset;
                    border: unset;
                }
            }

            .count-row {
                display: $d_flex;
                padding: 0px 10px 4px;


                &:last-child {
                    padding: 0 11px 0 0;
                }
                
                &:nth-last-child(2){
                    padding: 0 11px 1px 0;
                }

                .title {
                    max-width: 231px;
                    width: 100%;
                }

                .client_address {
                    max-width: 115px;
                    width: 100%;
                }

                &.standard {
                    .title {
                        color: $maroon !important;

                    }

                    .client_address {
                        color: $maroon;
                        margin-left: 40px;
                    }
                }

            }

        }

    }

    .select_drop_options {
        padding: 32px 85px 0 50px;

        @media (max-width:$large) {
            padding: 32px 20px 0 20px;

        }


        .ant-select-selector {
            background: #FFFFFF;
            border: 0.5px solid rgba(99, 99, 99, 0.5) !important;
            border-radius: 5px !important;
            height: 42px !important;
            padding: 5px 15px !important;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: unset !important;
            box-shadow: unset !important;
            border: 0.5px solid rgba(99, 99, 99, 0.5) !important;
        }

        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: unset !important;
        }

        .ant-select-arrow::before {
            content: '';
            position: $position_absolute;
            right: 12px;
            background: url('../image/down_arrow.svg'), no-repeat;
            width: 16px;
            height: 9.37px;
            top: 2px;
            pointer-events: none;
            display: block;
        }

        .anticon-down {
            display: $d_none;
        }

        .ant-select-selection-item {
            font-size: $f14;
            line-height: 30px !important;
            letter-spacing: 0.3125px;
            color: $dark_gray;
            font-family: "Poppins-Medium", sans-serif;

        }

        .client_sub_panel {
            padding: 32px 0 37px !important;
            display: $d_flex;
            justify-content: $justify_content_between;
            flex-wrap: $flex_wrap;

            ul {
                li {
                    padding: 0 32px 0px !important;
                    position: relative;

                    &:first-child {
                        padding: 0 32px 0 0 !important;
                    }

                    &.active {
                        a {
                            font-weight: $bold;
                            color: $dark_gray ;
                            position: $position_relative;

                            &::before {
                                content: '';
                                position: $position_absolute;
                                left: 10px;
                                background: $dark_gray;
                                width: 13px;
                                height: 2px;
                                bottom: -2px;
                            }
                        }
                    }

                    a {
                        font-weight: $normal;
                    }

                    &:last-child {
                        a {
                            background: unset;
                            color: unset;
                            color: #2E75B6 !important;
                            font-weight: $normal;
                            padding: 0px;


                        }
                    }
                }
            }

            .select_drop_option {
                @media (max-width:926px) {
                    padding: 20px 0 0;

                }

                p {
                    font-size: $f14;
                    line-height: $lineheight_20;
                    color: $dark_gray;
                    font-style: italic;
                    font-weight: $normal;
                    font-family: "Poppins-Medium", sans-serif;

                }
            }

        }

    }

    .delivery_date_range_detail {
        .delivery_date_range_main {
            display: $d_flex;
            align-items: $align_items_center;
            padding: 24px 52px 26px;
            background: #EBEBEB;
            border-bottom: 2px solid #F5F5F5;

            @media (max-width:$large) {
                flex-wrap: $flex_wrap;

            }

            .ant-checkbox-inner {
                margin-right: 36px;
                width: 15px;
                height: 15px;
                border: 2px solid #000000 !important;
                border-radius: 3px !important;
            }

            .ant-checkbox-checked::after {
                left: 3px;
                right: 3px;
                top: 2.5px;
                background-image: url(../image/tick.svg);
            }

            .normal_title {
                max-width: 242px;
                width: $w_100;

                p {
                    font-family: "Poppins-SemiBold", sans-serif;
                    font-weight: $semi_bold;
                    font-size: $f13;
                    line-height: $lineheight_20;
                    letter-spacing: 0.02em;
                    color: $dark_text_black;
                    position: $position_relative;
                    text-transform: $text_capitalize;

                }

                .select_btn {
                    margin: 18px 0 0;

                    .primary {
                        margin-left: 0px;
                        max-width: 99px;
                        padding: 11px 23px;
                    }
                }
            }

            .date_picker {
                padding: 0 100px 0 0;

                @media (max-width:$large) {
                    padding: 20px 0 0 0;

                }
            }

            &.exclude_main {
                display: $d_block;

                .exclude {
                    display: $d_flex;
                    align-items: $align_items_center;
                    flex-wrap: $flex_wrap;

                    .normal_title {
                        width: unset;
                    }

                    .number_count {
                        @media (max-width:901px) {
                            padding: 15px 0 0 0;

                        }

                        .ant-input-number-handler-wrap {
                            display: $d_none;
                        }

                        .ant-input {
                            background: $white;
                            border: 1px solid $light_gray_place;
                            border-radius: $radius_10;
                            width: 51px;
                            padding: 9px 15px 9px 15px;
                            margin: 0 12px 0 8px;
                            -webkit-appearance: none;
                            -moz-appearance: textfield;
                        }

                        .ant-input {
                            box-shadow: none;
                        }

                        .ant-form-item-control {
                            margin-bottom: 0px;
                        }

                        span {
                            font-family: "Poppins-SemiBold", sans-serif;
                            font-weight: $semi_bold;
                            font-size: $f13;
                            line-height: $lineheight_20;
                            letter-spacing: 0.02em;
                            color: $dark_text_black;
                            position: $position_relative;
                            text-transform: $text_capitalize;
                        }


                    }

                    .customers_count {
                        margin-left: 192px;

                        @media (max-width:$extraLarge) {
                            margin-left: 50px;

                        }

                        label {
                            font-weight: $semi_bold;
                            font-size: $f13;
                            line-height: $lineheight_20;
                            letter-spacing: $letter_02;
                            color: $dark_text_black;
                        }

                        .ant-input {
                            width: 128px;
                            margin: 0 22px 0 17px;
                        }
                    }

                }

                .type_click_select_year {

                    .ant-select-selector {
                        width: 128px !important;
                        border: 0px !important;
                        border-radius: 10px !important;
                        padding: 7px 22px !important;
                    }
                }

                .makes_detail {
                    display: $d_flex;
                    padding: 34px 0 27px 53px;
                    align-items: center;
                    flex-wrap: $flex_wrap;

                    @media(max-width:$large) {
                        padding: 20px 0 20px 0px;

                    }
                    .ant-select-selector {
                        width: 287px ;
                    }

                    .ant-select-selector {
                        background: $white;
                        border: 0.5px solid rgba(99, 99, 99, 0.5);
                        border-radius: $radius_5;
                        height: unset;
                        padding: 5px 15px;
                        align-items: center;
                        font-weight: $normal;
                        font-size: $f14;
                        line-height: $lineheight_20;
                        letter-spacing: 0.3125px;
                        color: $dark_text_black;

                    }

                    .ant-form-item-control {
                        margin-bottom: 0px;
                    }

                    .type_click_select {
                        @media (max-width:980px) {
                            margin: 15px 0 15px 0;

                        }

                        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
                            height: inherit !important;
                        }

                        .ant-select-selection-search {
                            color: #000;
                            display: flex;
                        }

                        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                            border-color: unset !important;
                            box-shadow: unset !important;
                            border: 0.5px solid rgba(99, 99, 99, 0.5) !important;
                        }

                        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
                            border-color: unset !important;
                        }

                        .ant-select-arrow::before {
                            content: '';
                            position: $position_absolute;
                            right: 0;
                            background: url('../image/down_arrow.svg'), no-repeat;
                            width: 16px;
                            height: 9.37px;
                            top: 2px;
                            pointer-events: none;
                            display: block;
                        }

                        .anticon-down {
                            display: $d_none;
                        }
                    }

                    .include_radio {
                        margin-left: 28px;

                        @media (max-width:980px) {
                            margin-left: 20px;


                        }

                        .ant-radio {
                            width: 14px;
                            height: 14px;
                        }

                        .ant-radio-inner {
                            border-color: $black;
                        }

                        .ant-radio-inner::after {
                            background-color: $black !important;
                        }

                        span.ant-radio+* {
                            padding-right: 12px;
                            padding-left: 12px;
                            font-weight: $semi_bold;
                            font-size: $f13;
                            line-height: $lineheight_20;
                            letter-spacing: 0.02em;
                            color: $dark_text_black;

                        }

                        .ant-radio-checked::after {
                            border: 1px solid $black;
                        }

                        &.active {
                            span.ant-radio+* {
                                color: $emali_color_text;

                            }
                        }

                    }

                    &.makes_sub {
                        padding: 0 0 0 !important;
                    }
                }

                .add_input_show {
                    margin: 16px 0 42px 295px;
                    overflow-y: auto;
                    max-height: 150px;

                    @media (max-width:$large) {
                        margin: 0 0 20px 20px;
                    }

                    .add_input_text {
                        p {
                            text-transform: $text_uppercase;
                        }
                    }


                }

                // .makes_detail {
                //     padding: 12px 0 0 53px;

                // }

            }
        }


    }

    .Add_jobs_button {
        margin: 78px 0 203px 104px;
        display: $d_flex;

        @media (max-width:$large) {
            margin: 50px 52px;

        }

        .primary {
            max-width: 233px;
            margin-left: 38px;
        }

        .btn-loader {
            height: unset !important;
            width: unset;
        }

    }


}


//quto information table

.table_quto {
    @media (max-width:1075px) {
        overflow: auto;
    }

    .makes_detail {
        align-items: flex-start !important;
    }

    .quto_information {
        max-width: 700px;
        width: $w_100;
        min-width: 700px;

        @media (max-width:1390px) {
            padding: 15px 0 0;

        }


        .quto_information_header {
            display: $d_flex;
            background: #D9D9D9;
            padding: 10px 0 8px 18px;
            border-radius: 5px 5px 0 0;

            .quto_main {
                min-width: 118px;

                p {
                    font-weight: $normal;
                    font-size: $f12;
                    line-height: $lineheight_20;
                    color: $black;
                    text-transform: $text_capitalize;
                }

                &.quote_line_id {
                    min-width: 143px;
                }

                &.count {
                    min-width: 103px;
                }

                &.ct {
                    min-width: 123px;
                }

                &:nth-child(3) {
                    min-width: 168px;
                }
            }

            &.quto_list {
                .quto_main {
                    &:first-child {
                        min-width: 260px;
                    }



                    &:last-child {
                        min-width: 378px;
                    }


                }


            }

        }

        .quto_desc {
            background: $white;
            border-radius: 0 0 5px 5px;
            padding: 11px 22px 38px 18px;
            max-height: 150px;
            overflow-y: scroll;
            overflow-x: hidden;

            .quto_desc_sub {
                display: $d_flex;

                .quto_number {
                    min-width: 118px;

                    &:nth-child(2) {
                        min-width: 120px;
                    }

                    &:nth-child(3) {
                        min-width: 166px;
                    }

                    &:nth-child(4) {
                        min-width: 243px;
                    }

                    p {
                        font-weight: $normal;
                        font-size: $f12;
                        line-height: $lineheight_20;
                        color: $black;
                        word-break: break-all;
                        white-space: normal;
                        display: -webkit-box;
                        max-width: 96px;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    button {
                        background: unset;
                        border: unset;
                    }
                }

                &.alesco_table {
                    .quto_number {
                        &:first-child {
                            min-width: 260px;
                        }

                        &:nth-child(2) {
                            min-width: 378px;
                        }

                    }
                }
            }
        }

    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 20px 1px 20px 20px #FFFFFF;
        border-radius: 0px;
    }


    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #636363;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #636363;
    }



}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
    background: #d9d9d9;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, .25) !important;
}

.makes_title {
    max-width: 340px !important;
    width: 100% !important;

    @media (max-width:$large) {
        max-width: unset !important;
        width: unset !important;

    }
}

.qutoe_id {
    display: $d_flex;
    margin-bottom: 10px;
    align-items: center;

    .quote_title {
        max-width: 141px;
        width: 100%;
        margin-right: 16px;
    }

    .ant-input {
        max-width: 201px;
        width: 100%;
        border-radius: $radius_10;
        border: 2px solid #DEDEDE;
        background: #F8F8F8;
        padding: 8px 11px;
        box-shadow: unset;
    }

    &:last-child {
        margin-bottom: 0px;
    }

}

.count-row-sub {
    border: 0.5px solid #000000;
    border-radius: 2px;
    padding: 5.5px 9px;
    max-width: 344px;
    width: 100%;
    
}

.standard {
    .list_image {
        margin-left: 12px;
        padding: 7px 0;
    }
    .client_address{
        float: right;
        width: 90px;
    }


}

.select_pop {
    text-align: center;
    padding: 0 0 30px 0;
}

.delete_icon {
    .delete_icon_del_btn {
        background: unset;
    }
}

.expanded-col {
    //   display: inline-table;
    width: $w_100;

    .action_icon {
        justify-content: end !important;
    }


}

.ant-table-cell {
    .status {
        .action_icon {
            justify-content: $justify_content_between !important;
        }
    }

    &.action {
        .action_icon {
            justify-content: unset !important;
        }

    }

}

.add_a_jobs_main{
    .client_history{
        .last_processed_ncoa{
            .count-row.standard .client_address{
                max-width: 91px;
            }
        }
    }
}