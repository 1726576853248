.user_detali {

  .client_panel {
    padding: 22px 47px !important;
  }
}

.transfer-page {
  width: 100%;
  height: 100vh;
  padding: 20px;
  background-color: #ffffff;

  .transfer-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    // width: max-content;
    background-color: #ffffff;

    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .transfer-boxes {
      // display: flex;
      // justify-content: space-between;

      .transfer_title {
        @media (max-width:987px) {
          margin-right: 28px;
        }

        p {
          font-family: 'FiraSansCondensed', sans-serif;
          font-weight: $semi_bold;
          font-size: 14px;
          line-height: $lineheight_20;
          letter-spacing: $letter_02;
          text-transform: $text_capitalize;
          color: $black;

        }
      }

      .ant-transfer-list {
        max-width: 500px;
        min-width: 300px;
        width: 100%;
        height: 300px;
      }

      .ant-transfer {
        width: 100%;
      }
    }

    .controls {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Filter {
        display: flex;
        gap: 20px;

        .select_box {
          .select_title {
            @media (max-width:987px) {
              margin-right: 28px;
            }

            p {
              font-family: 'FiraSansCondensed', sans-serif;
              font-weight: $semi_bold;
              font-size: 14px;
              line-height: $lineheight_20;
              letter-spacing: $letter_02;
              text-transform: $text_capitalize;
              color: $black;

            }
          }
        }

        .date_box {
          .date_range_title {
            @media (max-width:987px) {
              margin-right: 28px;
            }

            p {
              font-family: 'FiraSansCondensed', sans-serif;
              font-weight: $semi_bold;
              font-size: 14px;
              line-height: $lineheight_20;
              letter-spacing: $letter_02;
              text-transform: $text_capitalize;
              color: $black;

            }

          }

          .date_range {
            gap: 20px;
            display: flex;

            .begin_date_container {
              .begin_date {
                border: 1.5px solid $light_gray;
              }
            }

            .end_date_container {
              .end_date {
                // margin-left: 10px;
                border: 1.5px solid $light_gray;
              }

            }
          }
        }
      }

      .user_select_box {
        position: $position_relative;
        // max-width: $w_201;
        display: $d_block;
        appearance: none;
        width: 350px;
        // background: $dark_white;
        border: 1.5px solid $light_gray;
        border-radius: $radius_10;
        padding: 6.5px 10px;
        // color: #656565;
        font-size: $f14;
        line-height: $lineheight_20;
        letter-spacing: $letter3125;
        margin: 1.5px 0 0;
        font-family: 'Poppins-Regular', sans-serif;

        @media (max-width: $large) {
          max-width: $w_100;
        }

        &::before {
          content: '';
          position: $position_absolute;
          right: 7px;
          background: url('../image/down_arrow.svg'), $background-norepeat;
          width: 16px;
          height: 9.37px;
          bottom: 15px;
          pointer-events: none;
        }

        .ant-select {
          width: 100%;
        }

        .ant-select-arrow {
          display: block;
        }

        .ant-select-selector {
          // padding: 0px !important;
          // height: 20px !important;
          position: relative;
          background-color: unset !important;
          border: unset !important;
          border-radius: 0px !important;
          transition: unset !important;
          align-items: $align_items_center;
          box-shadow: unset !important;
        }

        .anticon-down {
          display: $d_none;
        }
      }


    }

    .export-button {
      display: flex;
      gap: 20px;

      .primary {
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }
}




.export-modal-content {

  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // padding: 20px;
  // background-color: #ffffff;
  .export-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;

    .primary {
      margin-left: 0px;
    }

  }
}