.header {
    position: $position_relative ;

    .header_menu {
        display: $d_flex;
        padding: 25px 0;
        align-items: $align_items_center;
        justify-content: $justify_content_between;

        @media (max-width:380px) {
            flex-wrap: $flex_wrap;
            justify-content: $justify_content_center;

        }

        .header_logo {
            width: $w_43;

            @media (max-width:$large) {
                width: $w_21;
            }

            @media (max-width:$mobileLarge) {
                width: 30%;

                @media (max-width:380px) {
                    width: $w_100;
                    margin-bottom: 20px;

                }

                img {
                    max-width: $w_120;

                    @media (max-width:$mobileLarge) {
                        margin: $m_auto;
                        max-width: 90px;
                    }

                    @media (max-width:380px) {
                        width: $w_100;
                        max-width: $w_120;

                    }
                }
            }
        }





        .header_title {
            width: 55.1%;


            @media (max-width:$large) {
                width: 37% !important;

            }

            @media (max-width:$ipad) {
                width: 60% !important;

            }

            @media (max-width:380px) {
                width: $w_100;
                text-align: $text_center;

            }

            h1 {
                position: $position_relative;
                font-size: $f32;
                line-height: $lineheight_28;
                font-family: "Poppins-ExtraBold", sans-serif;
                letter-spacing: $letter_02;
                text-transform: $text_uppercase;
                text-align: $text_right;
                display: $d_inline_block;
                background: $text_color_title;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media (max-width:$large) {
                    font-size: $f28;
                    line-height: $lineheight_35;
                }

                @media (max-width:$mobileLarge) {
                    font-size: $f22;
                    line-height: $lineheight_25 ;
                }

                &::before {
                    content: '';
                    position: $position_absolute;
                    right: -36px;
                    bottom: 13px;
                    background: url('../image/cloud.png'), $background-norepeat;
                    width: $w_32;
                    height: $h_32;

                    @media (max-width:$mobile) {
                        right: -28px;

                    }

                }

            }
        }




    }

    &.header_dashboard {
        @extend .header_menu;

        .header_title {
            @media(max-width:1680px) {
                width: 39.9% !important;
            }

            @media (max-width:$ipad) {
                width: 37% !important;

            }

            @media (max-width:380px) {
                width: 85% !important;
                text-align: $text_left  !important;

            }
        }
    }
}

.profile_logout {
    position: $position_absolute;
    z-index: 9;
    background: $white;
    max-width: $w_224;
    width: 100%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: $radius_10;
    display: $d_block;
    opacity: 0;
    pointer-events: none;
    width: 224px;
    left: auto;
    right: -18px;
    top: 55px;

    @media (max-width:991px) {
        top: 13%;
        right: 5px;

    }

    &::before {
        content: '';
        position: $position_absolute;
        top: -7px;
        right: 29px;
        background: url('../image/admin_profile.png'), $background-norepeat;
        width: 19px;
        height: 16px;
    }

    &::after {
        content: "";
        position: $position_absolute;
        left: 0;
        right: 0;
        top: -20px;
        height: 20px;


    }

    .logout_detali {
        .profile_detali {
            padding: 22px 27px 24px 27px;
            text-align: $text_right;

            li {
                padding-bottom: 9px;

                &.email_name {
                    a {
                        color: $emali_color;
                    }
                }

                span {
                    cursor: pointer;
                }

                a {
                    text-align: $text_right;
                    font-size: $f14;
                    line-height: $lineheight_21;
                    font-weight: $medium;
                    color: $text_color;
                    font-family: "Poppins-Regular", sans-serif;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    word-break: break-all;

                }

                &:last-child {
                    padding-bottom: 0px;
                }
            }
        }
    }

}

.moblie_menu {
    display: $d_none;

    @media (max-width:$large) {
        display: $d_block;
        cursor: pointer;

        img {
            width: 25px;
            height: 25px;
        }

    }
}

.header_admin {
    display: $d_flex;
    align-items: $align_items_center;
    justify-content: $justify_content_end;

    @media (max-width:$mobileLarge) {
        width: $w_100;
        padding: 20px 0 0;

    }

    .login_user_name {
        p {
            color: $black;
            // text-transform: $text_capitalize;
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: $letter1;
            font-weight: $semi_bold;

        }
    }

    .login_profile {
        position: $position_relative ;

        @media (max-width:991px) {
            position: unset;

        }

        .login_user_profile {
            width: 40px;
            height: $h_40;
            border-radius: $radius_50;
            overflow: hidden;
            margin-left: 17px;
            border: 2.5px solid $white;
            filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
            cursor: pointer;


            img {
                object-fit: cover;
                width: $w_100;
                height: $h_100;
            }
        }

        &:hover {
            .profile_logout {
                opacity: 1;
                transition: opacity 0.3s, transform 0.3s;
                pointer-events: auto;
                display: $d_block;
            }
        }
    }

}