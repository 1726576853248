.access-bg {
  height: 90vh;
}

.not_found_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  b {
    font-size: 100px;
    line-height: 100px;
  }
  span {
    font-size: 34px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    color: #bfbebe;
  }
  button {
    background: rgba(138, 3, 5, 0.1);
    border: none;
    padding: 10px 35px;
    border-radius: 24px;
    margin-top: 20px;
    color: #515151;
    font-weight: bold;
  }
}

.list-not-found {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &_wrapper {
    p {
      font-size: 24px;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
