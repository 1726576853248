.vin_date {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #000000;
}

.vin_title {
    font-weight: $semi_bold;
    font-size: $f13;
    line-height: $lineheight_20;
    letter-spacing: 0.02em;
    color: $black;
}

.upload_vindecoder_file {
    background-color: $primary;
    color: $white;
    width: 160px;
    height: 42px;
    border-radius: $radius_10;
    margin-left: 12px;
    border: 0px;

    &:hover,
    &:focus,
    &:active {
        background: $primary !important;
        border: transparent !important;
        color: $white !important;
    }

    &:hover:disabled {
    background: #d9d9d9 !important;
    }
    &:disabled {
    background: #d9d9d9 !important;
    }
}

.user_table_row {
    display: $d_flex;
    background: $white;
}

.user_table_sec {
    padding: 27px 0px 27px 48px;
    display: $d_flex;
    align-items: $align_items_center;
}

.in_progress {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #E91C23;
    margin-left: 50px;
}

.user_table_title {
    flex-grow: 0.8;
}

.user_table_date {
    flex-grow: 4;
}

.user_table_icon {
    flex-grow: 0.5;
    padding-right: 15px;
}

.action_icon {
    display: $d_flex;
}

@include customMedia(1024) {

    .user_table_title,
    .user_table_date,
    .user_table_icon {
        flex-grow: unset;
    }

    .user_table_sec {
        padding-left: 15px;
        width: $w_max_content;

        >span {
            width: $w_max_content;
        }
    }

    .user_table_row {
        justify-content: $justify_content_between;
        overflow-x: auto;
    }
}