//client_panel
.client_panel {
    padding: 27px 130px 26px 53px !important;

    @media (max-width:1272px) {
        padding: 27px 130px 26px 28px !important;

    }
}

//main panel
.client_main_panel {
    nav {
        ul {
            display: $d_flex;


            li {
                position: $position_relative;
                padding: 0 23px;

                &.active {
                    a {
                        color: rgba(0, 0, 0, 0.8);
                    }
                }

                &::before {
                    content: '';
                    position: $position_absolute;
                    right: 0;
                    top: 2px;
                    width: 9.6px;
                    height: 16.8px;
                    background: url('../image/right_arrow.svg'), $background-norepeat;
                }

                &:last-child {
                    &::before {
                        content: unset;
                    }

                    padding: 0 0 0 23px;
                    // max-width: 215px;
                }

                &:first-child {
                    padding: 0 23px 0 0;
                }


                a {
                    font-weight: $bold;
                    letter-spacing: $letter_02;
                    line-height: $lineheight_20;
                    text-transform: $text_capitalize;
                    color: $emali_color_text;

                }



            }
        }
    }
}

//sub panel

.client_sub_panel {
    padding: 30px 53px 0px !important;
    @extend .client_main_panel;


    @media (max-width:1272px) {
        padding: 30px 28px 0px 0px !important;

    }

    ul {
        flex-wrap: $flex_wrap;
    }

    li {

        padding: 0 28px 23px !important;

        &:first-child {
            padding: 0 28px 0 0px !important;

            @media (max-width:1272px) {
                padding: 0 28px 0 28px !important;

            }
        }



        &:last-child {
            padding: 0 28px 23px 28px !important;

            a {
                color: $white !important;
                background: #2E75B6;
                border-radius: 10px;
                padding: 2.5px 13px;
            }
        }

        &::before {
            background: $black !important;
            width: 1px !important;
            height: 21px !important;
            top: 0 !important;
        }


    }
}

//client from

.client_from {
    display: $d_flex;
    justify-content: $justify_content_between;
    padding: 36px 130px 70px 53px;

    @media (max-width:1350px) {
        padding: 31px 40px 70px 40px;

    }

    @media (max-width:1200px) {
        flex-wrap: $flex_wrap;

    }

    .client_from_main {
        max-width: 615px;
        width: $w_100;

        @media (max-width:1200px) {
            width: $w_100;
            max-width: $w_100;

        }

        .ant-form-item-label {
            flex: unset !important;
            max-width: unset !important;
            width: 20%;
            text-align: left !important;

            @media (max-width:$large) {
                width: 100%;

            }

            label {
                font-weight: $semi_bold;
                font-size: $f14;
                line-height: $lineheight_20;
                letter-spacing: 0.3125px;
                color: $dark_gray;
                text-transform: $text_capitalize;


                &::after {
                    content: unset !important;
                }

                &::before {
                    content: unset !important;
                }
            }


        }

        .ant-form-item-control {
            padding-left: 91px !important;
            width: 80%;
            max-width: unset !important;
            flex: unset !important;
            margin-bottom: 14px !important;

            @media (max-width:$large) {
                padding-left: 0px !important;
                width: 100%;

            }

            .ant-form-item-control-input {
                min-height: 42px !important;

                .ant-input {
                    background: $white !important;
                    border: 0.5px solid rgba(99, 99, 99, 0.5) !important;
                    border-radius: $radius_5 !important;
                    max-width: 348px;
                    padding: 10px 15px 10px;
                    font-weight: 400;
                    font-size: $f14;
                    line-height: $lineheight_20;
                    letter-spacing: 0.3125px;
                    color: $dark_gray;

                    &:focus {
                        box-shadow: unset !important;
                    }
                }
            }
        }

        .state_list {
            .ant-col .ant-form-item-control {
                padding-left: 0px !important;
            }

            .ant-input-group-compact {
                .ant-form-item {
                    max-width: 149px;
                    margin-right: 50px;
                    width: $w_100;

                    &:last-child {
                        margin-right: 0px;
                    }

                    .ant-form-item-control {
                        margin-bottom: 0px !important;

                    }
                }

            }

            .ant-select-arrow {
                height: 0px !important;
            }

            .ant-select-selection-item {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;
                white-space: break-spaces;
            }

            .ant-select-selector {
                height: 42px !important;
                background: $white !important;
                border: 0.5px solid rgba(99, 99, 99, 0.5);
                border-radius: $radius_5;
                box-shadow: unset !important;
                padding: 10px 15px !important;

                @media (max-width:$large) {
                    &:first-child {
                        margin: 0 0 10px;
                    }

                    &:last-child {
                        margin: 0 0 0px;
                    }
                }

                .ant-select-selection-placeholder {
                    padding: 0px !important;
                }

                .ant-select-selection-search {
                    left: 0px !important;
                    right: 0px !important;
                }

                .ant-select-selection-item {
                    font-weight: 400;
                    font-size: $f14;
                    line-height: $lineheight_20;
                    letter-spacing: 0.3125px;
                    color: $dark_gray;
                    padding: 0px !important;
                    text-transform: $text_capitalize;
                }

            }

            .ant-select-arrow {
                svg {
                    display: $d_none;
                }

                position: unset !important;

                &::before {
                    content: '';
                    position: $position_absolute;
                    right: 12px;
                    background: url('../image/down_arrow.svg'), $background-norepeat;
                    width: 16px;
                    height: 9.37px;
                    top: 16px;
                    pointer-events: none;
                    display: $d_block;
                }
            }


        }

    }

    .location {
        padding: 16px 0 24px;

        .primary {
            max-width: 160px;
            margin-left: 0px;
            padding: 11px 18px !important;
            margin-right: 52px;
            vertical-align: top;
        }

        .location_set {
            display: $d_inline_block;
            width: 60%;
            border-radius: $radius_5;
            overflow: hidden;

            @media(max-width:991px) {
                width: $w_100;
                padding: 25px 0 0;
            }

            .map-container {
                border-radius: $radius_5;
                overflow: hidden;
                width: 346px;
                height: 152px;
                border: unset
            }
        }

        .error_msg_icon {
            display: $d_inline_block;
            vertical-align: top;
            position: $position_relative;
            margin-left: 16px;


        }

        .sucess_error {
            vertical-align: top;
            // margin-left: 16px;

        }


    }


    .latitude {
        padding-bottom: 13px;

        .latitude_title {
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: 0.3125px;
            color: $dark_gray;
            text-transform: $text_capitalize;
            margin-right: 39px;
            padding: 0 0 0 13px;

            &:last-child {
                margin-right: 0px;
                padding: 0 6px 0 0;
            }

            &.error_icon {
                position: relative;

                &::after {
                    content: '';
                    position: $position_absolute;
                    width: 24px;
                    height: 24px;
                    top: -3px;
                    right: -25px;
                    background: url('../image/error.svg'), $background-norepeat;
                }

            }
        }
    }


    textarea {
        resize: none;
        height: 153px;
    }

    .notes {
        padding-bottom: 41px;
        // border-bottom: 1px solid #636363;
        position: $position_relative;

        &::before {
            content: '';
            position: $position_absolute;
            left: 0;
            width: 560px;
            height: 1px;
            background: #636363;
            bottom: 0;

        }
    }

    .autoComplete {
        position: relative;

        &_list {

            background-color: #fff;
            overflow: scroll;
            max-width: 356px;
            position: absolute;
            z-index: 1;
            width: 100%;
            right: 0;
            left: 0;
            margin: 0 auto;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            margin: 0 0 0 210px;
            top: 42px;
            transition: all .3s ease-in-out;
            visibility: hidden;
            max-height: 0px;

            @media (max-width:$large) {
                margin: 0 0 0 0px;
                top: 75px;

            }


            &.active {
                max-height: 170px;
                transition: all .3s ease-in-out;
                visibility: visible;
                border: 1px solid #DEDEDE;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                cursor: pointer;
                padding: 7px 20px;
                margin: 6px;

                &:hover {
                    background: #DEDEDE;
                    color: $black;
                    border-radius: 4px;
                }
            }
        }
    }

    .contact_detail {
        &.first_name {
            padding-top: 32px;
        }

        &.number_input {
            background: #ffffff !important;
            border: 0.5px solid rgba(99, 99, 99, 0.5) !important;
            border-radius: 5px !important;
            max-width: 348px;
            padding: 5px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            color: #595959;
        }

        padding-bottom: 10px;

    }

}

.client_button_from {
    padding: 48px 0px 0px 54px !important;
}

//client id

.client_list_id {
    @media (max-width:1200px) {
        padding-top: 50px;

    }

    p {
        font-size: 20px;
        font-family: "Poppins-Bold";
        font-weight: $bold;
        line-height: $lineheight_20;
        text-align: $text_right;
        letter-spacing: $letter_02;
        text-transform: $text_capitalize;
        padding-bottom: 28px;

        @media (max-width:1200px) {
            text-align: $text_left;

        }
    }


}

//client button

.client_button {
    button {
        color: $white;
        font-size: $f14;
        line-height: $lineheight_20;
        text-align: $text_center;
        letter-spacing: 0.3125px;
        font-weight: $normal;
        border-radius: $radius_10;
        padding: 10px 24px;
        border: transparent;
        max-width: 233px;
        width: 233px;
        margin-bottom: 15px;

        svg {
            margin: 0 6px 0 0px;
            vertical-align: middle;
        }

        &.add_a_job {
            background: #7498B9;

        }

        &.clean {
            background: #61956D;
        }

        &.add_ncoa {
            background: #946C9D;
        }

        &.add_fav {
            background: #2E75B6;
            margin-bottom: 0px;
        }
    }
}


.re-ncoa-request {
    max-width: max-content !important;
    width: 100% !important;
}

//advance setting

.advanced_title {
    padding: 27px 103px 25px 54px;

    @media (max-width:$large) {
        padding: 27px 20px 25px 20px;
    }

    p {
        font-weight: $semi_bold;
        font-size: $f14;
        line-height: $lineheight_20;
        letter-spacing: 0.02em;
        color: $black;
    }

}

.collapsible_button {
    display: $d_flex;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: 144px;

    .collapsible {
        background: $white;

        /* width */
        ::-webkit-scrollbar {
            width: 22px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            box-shadow: inset 20px 1px 20px 20px #dedede;
            border-radius: 0px;
        }


        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #636363;
            border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #636363;
        }

        .collapsible_header {
            display: $d_flex;
            align-items: $align_items_center;
            padding: 26px 56px 26px;
            background: #EBEBEB;
            cursor: pointer;
            border-bottom: 2px solid #F5F5F5;

            &:last-child {
                border-bottom: 0px;
            }

            .ant-checkbox-inner {
                margin-right: 36px;
                width: 15px;
                height: 15px;
                border: 2px solid $black !important;
                border-radius: 3px !important;
            }

            &.active {
                .collapsible_title {

                    p {
                        &::before {
                            content: '';
                            position: $position_absolute;
                            background: $emali_color_text;
                            width: 22px;
                            height: 2px;
                            bottom: -5px;
                            left: 0;

                        }
                    }
                }
            }

            .normal_title {

                p {
                    font-family: "Poppins-SemiBold", sans-serif;
                    font-weight: $semi_bold;
                    font-size: 13px;
                    line-height: $lineheight_20;
                    letter-spacing: $letter_02;
                    color: $dark_text_black;
                    position: $position_relative;


                }
            }

            .collapsible_title {
                @extend .normal_title;


                p {
                    color: $emali_color_text;
                }
            }

            .collapsible_count {
                margin-left: 26px;
                display: $d_flex;
                background: rgba(93, 115, 126, 0.5);
                width: 24px;
                height: 24px;
                min-width: 24px;
                border-radius: $radius_50;
                align-items: $align_items_center;
                justify-content: $justify_content_center;

                p {
                    color: $white;
                    font-size: 10px;
                    font-weight: $semi_bold;
                    line-height: $lineheight_20;
                    letter-spacing: $letter_02;

                }
            }

            .ant-checkbox-checked::after {
                left: 3px;
                right: 3px;
                top: 2.5px;
                background-image: url('../image/tick.svg');
                animation: none;

            }

        }

        .content {
            padding: 25px 20px 104px 54px;


            .add_input_field {
                display: $d_flex;
                align-items: $align_items_center;
                padding-bottom: 25px;

                @media (max-width:$large) {
                    flex-wrap: $flex_wrap;

                }

                .add_input {
                    max-width: 309px;
                    width: $w_100;
                    margin-right: 22px;

                    .ant-form-item-row {
                        .ant-form-item-control {
                            margin-bottom: 0;
                        }
                    }

                    .ant-input {
                        background: rgba(134, 134, 134, 0.1);
                        border-radius: 20px;
                        border: transparent;
                        padding: 10px 18px;
                        font-size: 14px;
                        font-family: 'FiraSansCondensed', sans-serif;
                        color: #5D737E;
                        line-height: $lineheight_20;
                        letter-spacing: $letter_02;
                        font-weight: $semi_bold;
                        text-transform: $text_capitalize;

                        &:focus {
                            border: unset !important;
                            box-shadow: unset !important;
                        }
                    }

                }

                .title_add {
                    display: $d_flex;
                    align-items: $align_items_center;

                    @media (max-width:$large) {
                        padding: 20px 0 0;

                    }



                    button {
                        color: $maroon;
                        font-size: $f14;
                        line-height: $lineheight_20;
                        letter-spacing: $letter_02;
                        text-transform: $text_capitalize;
                        font-weight: $semi_bold;
                        padding: 0 32px 0 0;
                        background: transparent;
                        border: none;
                    }
                }

            }





        }
    }

    .primary {
        @extend .clear;
        background: $primary !important;
        color: $white !important;
        max-width: 160px !important;
        padding: 11px 18px !important;
        text-align: center;
        border-radius: 10px !important;
        font-size: 14px !important;
        line-height: 20px !important;
        letter-spacing: 0.3125px !important;
        font-family: "Poppins-Regular", sans-serif;
        text-transform: capitalize !important;
        margin-left: 0px;

        @media (max-width:$mobile) {
            margin: 20px 0 0;
        }


    }

}

.add_input_show {
    display: $d_flex;
    flex-wrap: $flex_wrap;
    max-height: 156px;
    //overflow-y: auto;
    margin-bottom: 43px;

    .add_input_text {
        background: #D9D9D9;
        border-radius: $radius_20;
        padding: 9px 7px 9px 15px;
        display: $d_flex;
        align-items: $align_items_center;
        margin: 0 6px 12px;

        &:first-child {
            margin: 0 6px 12px 0;
        }

        p {
            color: $black;
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: $letter_02;
            font-family: 'FiraSansCondensed', sans-serif;
            font-weight: $semi_bold;
            min-width: 66px;
            word-break: break-all;

        }

        span {
            cursor: pointer;
            padding-left: 20px;

            svg {
                vertical-align: middle;
            }
        }
    }
}

//button

.client_button_from {
    padding: 48px 53px 0;

    .clear {
        max-width: 154px;
        font-weight: $medium;
        padding: 11px 18px !important;
        color: $black;
        margin-right: 38px;
        margin-bottom: 15px;
    }

    .primary {
        max-width: 154px;
        padding: 16px 20px;
        color: $white;
        margin-bottom: 15px;
    }
}
.download_cancel_btn {
    margin-left: 10px;
}
//clean file

.date_range_panel {
    background: $dashborad_sidebar;
    padding: 16px 130px 16px 54px;

    @media (max-width:1272px) {
        padding: 16px 28px 16px 28px;
    }

    .date_range {
        display: $d_flex;
        justify-content: $justify_content_between;
        align-items: baseline;
        flex-wrap: $flex_wrap;

        @media (max-width:1252px) {
            flex-wrap: $flex_wrap;

        }

        .date_range_sub {
            display: $d_flex;
            align-items: baseline;
            flex-wrap: $flex_wrap;


            .date_range_title {
                @media (max-width:987px) {
                    margin-right: 28px;
                }

                p {
                    font-family: 'FiraSansCondensed', sans-serif;
                    font-weight: $semi_bold;
                    font-size: 14px;
                    line-height: $lineheight_20;
                    letter-spacing: $letter_02;
                    text-transform: $text_capitalize;
                    color: $black;

                }
            }




        }

        .clean_a_btn {
            @media (max-width:1252px) {
                padding-top: 15px;

            }

            .clean {
                margin-bottom: 0px;
            }

            .add_ncoa {
                margin-bottom: 0px;
                padding: 14px 24px !important;
            }

        }
    }
}

//date picker

.ant-picker-input:hover .ant-picker-clear {
    display: none;

}

.ant-picker-dropdown {
    width: 100%;
    max-width: 350px;
}

.ant-picker-panel-container {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: $radius_10;
}

.ant-picker-date-panel {
    width: 350px;
}

.ant-picker-date-panel .ant-picker-body {
    padding: 22px 37px 29px;
}

.ant-picker-panel .ant-picker-footer,
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
    display: none;
}

.ant-picker-header {
    padding: 36px 37px 0px;
    border: 0px;
}

.ant-picker-year-btn, .ant-picker-month-btn{
    pointer-events: none;
}

.ant-picker-header button {
    line-height: 40px;
    font-family: 'Raleway', sans-serif;
    font-weight: $bold;
    font-size: 16px;
    line-height: 19px;
    text-align: $text_center;
    color: $black;
}

.ant-picker-content th {
    font-style: normal;
    font-weight: $normal;
    font-size: 10px;
    line-height: 12px;
    text-align: $text_center;
    text-transform: $text_uppercase;
    color: #1784E9;
    font-family: "Raleway-Regular", sans-serif;
    height: unset;
    width: unset;
}

.ant-picker-date-panel .ant-picker-content {
    width: 273px;
}

.ant-picker-cell {
    padding: 7px 0;
}

.ant-picker-cell .ant-picker-cell-inner {
    font-family: 'GothamMedium', sans-serif;
    font-weight: $normal;
    font-size: 13px;
    line-height: 16px;
    margin: 0 auto;
    text-align: $text_center;
    color: $black;
    min-height: unset;
    height: unset;
    position: unset;
    display: unset;

}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: unset;
}

.ant-picker-cell-selected {
    background: #457B9D;
    // width: 32px;
    // height: 32px;
    border-radius: $radius_50;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    // background: #457B9D;
    // width: 32px;
    // height: 32px;
    color: $white !important;
    background: transparent !important;
    // border-radius: $radius_50;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    ::i-block-chrome,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        line-height: 40px;
        display: block;
    }
}

// ::i-block-chrome,
// .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
// .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
//     display: block;
//     line-height: 40px;
// }

// @media not all and (min-resolution:.001dpcm){
// 	.ant-picker-cell-inner{
//         display: block;
// 		line-height:40px
// 	}
// }

.ant-picker-prev-icon::before {
    background-image: url('../image/cal_left.svg');
    background-repeat: $background-norepeat;
    width: 10px;
    height: 10px;
    border-width: unset;
    border: 0;
}

.ant-picker-next-icon::before {
    background-image: url('../image/cal_right.svg');
    background-repeat: $background-norepeat;
    width: 10px;
    height: 10px;
    border-width: unset;
    border: 0;
}

.ant-picker-prev-icon,
.ant-picker-next-icon {
    transform: unset;
    height: 10px;
}

//clean table

.clean_table {
    tbody {
        tr {
            .ant-table-cell {
                &:nth-child(1) {
                    color: $black !important;
                    padding: 16px 0px 16px 48px !important;
                }

                padding: 16px 0px 16px 11px !important;
            }
        }
    }


}

//clean file module

.clean_file_sub_module {
    padding: 26px 0 50px 54px;
    max-width: 668px;

    @media (max-width:$large) {
        padding: 26px 20px 50px 20px;

    }

    .clean_a_file_label {
        label {
            color: $black !important;
        }
    }

    .ant-row {
        justify-content: $justify_content_between;

        @media (max-width:$large) {
            flex-wrap: wrap;

        }

    }

    .ant-form-item-label {
        flex: unset !important;
        max-width: unset !important;
        text-align: left !important;

        @media (max-width:$large) {
            max-width: 100% !important;
            width: 100%;

        }



        label {
            font-weight: $semi_bold;
            font-size: $f14;
            line-height: $lineheight_20;
            letter-spacing: 0.3125px;
            color: $dark_gray;
            text-transform: $text_capitalize;


            &::after {
                content: unset !important;

            }

            &::before {
                content: unset !important;

            }
        }




    }

    .ant-form-item-control {
        margin-bottom: 28px !important;
        max-width: 479px;
        width: 100%;

        @media (max-width:$large) {
            flex: unset;

        }
    }

    .ant-input {
        border: 0.5px solid rgba(99, 99, 99, 0.5);
        border-radius: 5px;
        padding: 9px 14px;
        font-weight: $medium;
        font-size: $f14;
        line-height: $lineheight_20;
        letter-spacing: 0.3125px;
        color: #76919F;


        &:focus {
            box-shadow: unset;
        }
    }

    .clean_module_btn {
        .primary {
            max-width: 160px;
            padding: 11px 18px !important;
            vertical-align: top;
            margin: 28px 0 28px 134px;


            @media (max-width:$large) {
                margin-left: 0px;

            }
        }
    }

    .optional {
        textarea {
            resize: none;
            height: 84px;

            &:focus {
                box-shadow: unset;
            }
        }
    }

    .clean_file_main {
        text-align: $text_center;
        display: $d_flex;
        justify-content: $justify_content_center;
        margin-left: 83px;

        @media (max-width:$large) {
            margin-left: 0px;

        }

        .clean {
            font-weight: $semi_bold;
        }

        .client_button {
            margin-left: 38px;

            .clean {
                margin-bottom: 0px;
                padding: 14px 12px;
            }

            .add_ncoa {
                margin-bottom: 0px;
                padding: 14px 24px;
            }
        }

    }

    .clean_a_file_label {
        .ant-form-item-control {
            display: $d_none;
        }
    }

}

// go button
.go_btn {

    @media (max-width:987px) {
        padding: 15px 15px 0 15px;
    }

    button {
        background: transparent;
        border: transparent;
        font-weight: $semi_bold;
        font-size: 14px;
        line-height: $lineheight_20;
        letter-spacing: 0.02em;
        text-transform: $text_capitalize;
        color: #8A0305;
        padding: 0 14px 0 0;

        &:last-child {
            padding: 0 0px 0 0;
        }

    }

}


//streets setting
.streets {
    padding: 0 !important;
    overflow: unset !important;
    padding-bottom: 0px !important;
    height: auto !important;
    max-height: unset !important;

    .add_input_show {
        padding: 0 0 42px;
    }

    .add_input_field {
        padding: 25px 20px 104px 54px;
    }

    .add_input_select {
        display: $d_flex;
        align-items: $align_items_center;

        &.select {
            background: #D9D9D9;
            padding: 14px 106px;
            width: $w_100;
        }

        .collapsible_title {
            display: $d_flex;

            p {
                font-family: "Poppins-SemiBold", sans-serif;
                font-weight: $semi_bold;
                font-size: $f14;
                line-height: $lineheight_20;
                letter-spacing: $letter_02;
                color: $dark_text_black;
                position: $position_relative;
                text-transform: $text_capitalize;


            }
        }
    }

    .show_select {
        width: 100%;
        background: #FFFFFF;
        max-height: 156px;
        overflow-y: auto;

        .add_input_select {
            &.select {
                padding: 14px 106px;
                border-bottom: 1px solid #C4C4C4;
                background: unset;

            }

        }

        .ant-checkbox-inner {
            border: 2px solid #636363 !important;
        }
    }

    .ant-checkbox-inner {
        margin-right: 13px;
        width: 15px;
        height: 15px;
        border: 2px solid $black !important;
        border-radius: 3px !important;
    }

    .ant-checkbox-checked::after {
        left: 3px;
        right: 3px;
        top: 2.5px;
        background-image: url('../image/tick.svg');
        animation: none !important;
        animation-fill-mode: unset !important;
    }


}

//new client panel

.client_name_panel {
    padding: 16px 130px 17px 69px !important;

    .client_title_new {
        p {
            font-weight: $bold;
            font-size: $f16;
            line-height: $lineheight_20;
            letter-spacing: 0.05em;
            text-transform: $text_uppercase;
            color: $black;
        }
    }

    .cancel_client_btn {
        max-width: 160px;
        width: $w_100;

        @media(max-width:1183px) {
            padding: 20px 0 0 0;

        }

        button {
            background: $maroon;
            color: $white;
            font-weight: $normal;
            font-size: $f14;
            line-height: $lineheight_20;
            text-align: $text_center;
            letter-spacing: 0.3125px;
            padding: 11px 18px;
            border: unset;
            border-radius: $radius_10;
            max-width: 160px;
            width: $w_100;
        }
    }
}

//ncoa table

.noca_table {
    thead {
        tr {
            th {
                &:nth-child(1) {
                    padding: 9px 0px 10px 48px;

                    &::before {
                        content: unset;
                    }
                }

                &:nth-child(2) {
                    padding: 9px 36px 10px 22px !important;
                }
            }

        }

        .ant-checkbox-inner {
            width: 15px !important;
            height: 15px !important;
            border-radius: 3px !important;
            border: 2px solid $white !important;
            background: unset;
            z-index: 9;
        }

        .ant-checkbox-checked::after {
            background-image: url('../image/tick_white.svg');
            z-index: 9;
        }

    }

    tbody {
        tr {
            .ant-table-cell {
                &:nth-child(1) {
                    padding: 17px 22px 16px 48px !important;
                }

                &:nth-child(2) {
                    padding: 17px 36px 16px 22px !important;
                }

                padding: 17px 11px 16px 11px !important
            }
        }

        .ant-checkbox-inner {
            width: 15px !important;
            height: 15px !important;
            border-radius: 3px !important;
            border: 2px solid $black !important;
            background: unset;
            z-index: 9;
        }
    }

    .ant-checkbox-checked::after {
        left: 3px;
        right: 3px;
        top: 2.5px;
        background-image: url('../image/tick.svg');
    }

    .ant-table-tbody>tr.ant-table-row-selected:hover>td {
        background: $white;
    }

    .status {
        &::before {
            content: unset !important;
        }

    }


}

.delete_btn {
    background: #8A0305;
    border-radius: 5px;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border: 1px solid transparent;
    cursor: pointer;
    margin: 0 0 0 11.5px;
    padding: 0px !important;

    &:hover,
    &:focus,
    &:active {
        background: $maroon !important;
        border: transparent !important;
    }

    &:disabled {
        background: #d6d6d6;
    }
}


.downloads_btn {
    @extend.delete_btn;
    background: #2E75B6;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        background: #7498B9 !important;
        border: transparent !important;
    }
}

.downloads_btn_yellow {
    @extend.delete_btn;
    background: #d1b63a;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        background: #d1b63a !important;
        border: transparent !important;
    }
}

.download_purpal_btn {
    @extend.delete_btn;
    background: #946C9D;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        background: #946C9D !important;
        border: transparent !important;
        color: white !important;
    }

    &:hover:disabled {
        background: #d9d9d9 !important;
    }
}

.download_green_btn {
    @extend.delete_btn;
    background: #29B147;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        background: #29B147 !important;
        border: transparent !important;
        color: white !important;
    }

    &:hover:disabled {
        background: #d9d9d9 !important;
    }
}

.file_btn {
    @extend .delete_btn;
    background: #7498B9;
    box-shadow: none;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
        background: #7498B9 !important;
        border: transparent !important;
    }

    &:hover:disabled {
        background: #d9d9d9 !important;
    }

}

.ant-space {
    gap: 0px;
}

.action_icon {
    .ant-space-item {
        padding: 0 30px 0 0;
    }

    .record_status {
        min-width: 90px;
    }

    .status_button {
        display: $d_flex;
        // padding: 0 53px 0 61px;
        padding: 0 13px 0 61px;

    }

    .status_button {
        span {
            margin-right: 9px !important;

        }



        button {
            margin-right: 9px !important;
            padding: 0px !important;

            &:last-child {
                margin-right: 0px !important;
            }
        }
    }

}

//user_list 
.user_list {
    .user_detali {
        width: 100% !important;
    }
}

//add jobs drops
.job_drops {
    padding: 17px 68px 16px 54px;

    @media (max-width: 1272px) {
        padding: 17px 20px 16px 20px;

    }

    .date_range {
        align-items: unset;
    }

    .date_serach {
        display: $d_flex;

        .user_search {
            @media (max-width:$large) {
                width: 100%;

            }
        }

        @media (max-width:1333px) {
            flex-wrap: $flex_wrap;

        }
    }

    .serach_input {
        font-family: "Poppins-Regular", sans-serif !important;

        &::placeholder {
            font-family: "Poppins-Regular", sans-serif !important;
        }
    }

    .close_image {
        position: $position_absolute;
        right: 7px;
        bottom: 13.67px;
        cursor: pointer;
    }

    .date_range_sub {
        padding: 0 0 0 58px;

        @media (max-width:1333px) {
            padding: 0 0 0 20px;
        }

        @media (max-width:1215px) {
            padding: 15px 0 0 20px;
        }

        @media (max-width:$large) {
            padding: 15px 0 0 0px;
        }
    }

    .go_btn {
        padding: 0 0 0 40px;

        @media (max-width:$ipad) {
            padding: 10px 0 0 0px;

        }

    }

    .status_button {
        display: $d_flex;

        @media (max-width:1502px) {
            padding: 15px 0 0;

        }


        span {
            margin: 0 11.5px;

            &:first-child {
                margin: 0 11.5px 0 0;
            }

            &:last-child {
                margin: 0 0 0 11.5px;
            }
        }
    }



}

.quotes_table {
    .ant-table-filter-trigger {
        padding-left: 41px !important;
    }

    .ant-table-row-expand-icon-cell {
        &::before {
            content: unset !important;
        }
    }

    .ant-table-expanded-row {
        .ant-table-cell {
            background: #DEDEDE !important;

            .expanded-col {
                td {
                    background: #DEDEDE !important;
                    border-bottom: 1.5px solid #ffff;
                    width: 200px;

                    &:nth-child(1) {
                        width: 290px
                    }

                    &:nth-child(2) {
                        width: 205px
                    }

                    &:nth-child(3) {
                        width: 130px
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 0px solid;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            .ant-table-cell {
                &:nth-child(1) {
                    padding: 17px 22px 16px 18px !important;

                }

                &:nth-child(2) {
                    color: $emali_color_text !important;
                    cursor: pointer;

                }

                &:nth-child(3) {
                    color: $emali_color_text !important;

                }
            }
        }
    }

    thead {
        tr {
            th {
                &:nth-child(1) {
                    padding: 0 18px !important;
                }

            }
        }

    }
}

.type_filter {
    .modal_detail {
        padding: 24px 18px 16px 24px;
    }

    .ant-checkbox-wrapper {
        display: $d_flex;
    }

    .ant-checkbox-inner {
        width: 15px !important;
        height: 15px !important;
        border-radius: 3px !important;
        border: 2px solid $black !important;
        background: unset;
    }

    .ant-checkbox-checked::after {
        left: 3px;
        right: 3px;
        top: 2.5px;
        background-image: url(../image/tick.svg);
    }

    .ant-checkbox+span {
        line-height: 21px;
        color: rgba(37, 40, 44, 0.8);
        text-align: right;
        width: 100%;
        text-transform: $text_capitalize;
    }

    .filter_check {
        ul {
            li {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .go_btn {
        display: $d_flex;
        padding-top: 16px;
        justify-content: right;

    }
}

.ant-table-expanded-row.ant-table-expanded-row-level-1.expanded-col .ant-table-cell {
    padding: 0 !important;
}


//export module
.export_main {
    padding: 25px 54px;

    .report_title {
        color: $black;
        font-weight: $bold;
        font-size: $f14;
        line-height: $lineheight_20;
        letter-spacing: $letter_02;
        padding-bottom: 25px;
    }

    .export_processed_detail {
        display: $d_flex;
        align-items: $align_items_center;
        padding: 0 0 20px;

        &:last-child {
            padding: 0 0 0;
        }

        .ncoa_processed_btn {
            max-width: 378px;
            width: 100%;

            button {
                background: $primary;
                max-width: 378px;
                width: 100%;
                border-radius: $radius_10;
                color: $white !important;
                padding: 10px 17px;
                font-size: $f14;
                line-height: $lineheight_20;
                letter-spacing: 0.3125px;
                border: 1px solid transparent;
                text-transform: $text_capitalize;
                height: 100%;
            }



        }

        .export_icon {
            padding: 0 26px 0 11px;

            @media (max-width:1110px) {
                width: 24.5%;

            }
        }

        .tooltiptext {
            -webkit-line-clamp: 3;
            width: 100%;
            max-width: 154px;
            min-width: 154px;
            height: 100%;
            min-height: 46px;
            max-height: 46px;
            top: -55px;
            font-size: 9px;
            line-height: 12px;


            &::before {
                background-image: url('../image/export_bottom_triangle.svg');
                width: 12px;
                height: 10px;
                bottom: -6.9px;

            }
        }

        .last_export_title {
            p {
                font-size: $f14;
                line-height: $lineheight_20;
                color: $black;
                letter-spacing: $letter_02;
                font-style: italic;
            }
        }
    }
}



//date picker

.date_picker {
    display: $d_flex;
    padding: 0 23px;
    align-items: baseline;

    @media (max-width:987px) {
        padding: 0 0;
    }



}

.ant-picker {
    background: $white;
    border-radius: $radius_10;
    max-width: 128px;
    padding: 10px 22px;
    border: none;

    .ant-picker-suffix {
        display: $d_none;
    }

    &.ant-picker-focused {
        border: none;
        box-shadow: none;
    }
}

.dess {
    padding: 0 10px;
}

.delete_icon_del_btn {
    border: unset;
    height: unset;
    padding: 0;
}

//upload ncoa

.clean_module_btn {
    display: $d_flex;
    margin: 28px 0 28px 134px;
    width: $w_100;
    flex-wrap: $flex_wrap;

    @media (max-width:$large) {
        margin: 28px 0 28px 0;

    }

    span {
        &:first-child {
            width: 26.1%;

            @media (max-width:$large) {
                width: $w_100;

            }

        }

    }

    .ant-upload {
        width: 100%;

        @media (max-width:$large) {
            margin: 0px 0 0px 0px !important;

        }
    }

    .select_clean {
        @media (max-width:$large) {
            margin: 20px 0 0 !important;
            width: $w_100;

        }

    }
}

.select_clean {
    max-width: 293px !important;
    width: $w_100;
    margin: 0px 0 0px 27px !important;
    padding: 13px 11px;

    @media (max-width:$large) {
        margin: 0 0 0px 27px !important;

    }
}

.upload_ncoa {
    .primary {
        margin: 0px 0 0px 0px !important;
        height: 46px;
    }

    .btn-loader {
        width: 80px;
    }

}

//information

.information {
    display: $d_flex;
    min-width: 140px;

    .information_sub {
        width: 20px;
    }
}

.ncoa_cancel {
    background: $primary;
    max-width: 160px !important;
    width: $w_100;
    margin-bottom: 0px !important;
}

.status_button {

    .ant-btn:hover,
    .ant-btn:focus,
    .ant-btn:active {
        background: $primary;
        color: $white;
    }



    .primary {
        span {
            padding: 6px 15px;



        }
    }
}

[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
    box-shadow: unset !important;
    opacity: 0 !important;
}

.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
    box-shadow: unset;
}

.list_image {
    cursor: pointer;
}

.fix_status_button {
    padding: 0 0 0 0px !important;
}

.noca_file {
    .action_icon {
        justify-content: unset !important;

        .information {
            min-width: 90px;
        }

        .ant-space-item {
            padding: 0 0 0 0;
        }

        .status_button {
            padding: 0 13px 0 20px;
        }
    }

//     .ant-table-body {
//         // overflow-x: hidden !important;
//         overflow-y: auto !important;
//     }
}

.remove_selected {
    color: $maroon !important;
    font-size: $f14 !important;
    line-height: $lineheight_20 !important;
    letter-spacing: $letter_02 !important;
    text-transform: $text_capitalize !important;
    font-weight: $semi_bold !important;
    padding: 0 32px 0 0 !important;
    cursor: pointer !important;
    margin: 0 0 0 35px;
}

.title_add {
    .remove_selected {
        margin: 0
    }
}

.btn-loader {
    height: 24px !important;
    // width: 198px;
    max-width: 198px;
}

.noca_file {
    thead {
        tr {
            th {
                &:nth-child(1) {
                    padding: 13px 11px 10px 11px !important;
                }
            }

        }
    }

    tbody {
        tr {
            .ant-table-cell {
                &:nth-child(1) {
                    padding: 17px 11px 16px 11px !important;
                }
            }
        }

    }

}