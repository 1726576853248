//color name
$white: #ffffff;
$black: #000000;
$gray_color: #999;
$maroon: #8A0305;
$text_color_title: linear-gradient(90.41deg, #8A0305 0%, #EC1C24 100%);
$black_dark: #1A1A1A;
$dark_gray: #595959;
$dark_white: #F8F8F8;
$light_gray: #DEDEDE;
$light_gray_place: #C4C4C4;
$text_black: #454545;
$dark_text_black: #636363;
$clear: #D9DBE1;
$primary: #2E75B6;
$footer_bg: #464646;
$focus_input: #DADADA;
$dashborad_sidebar: rgba(138, 3, 5, 0.1);
$table_color: #F5F5F5;
$search_color: #C4C4C4;
$error: red;
$text_color: rgba(37, 40, 44, 0.8);
$emali_color: rgba(93, 115, 126, 0.5);
$white_bg: #F0F0F0;
$checkbox_color: #6F88A6;
$input_round: #DE2B2B;
$emali_color_text: #1784E9;
$gray: #5D737E;
$light_blue: #1890ff;
//media query
$smallMobile: 325px;
$mobileSmall: 375px;
$mobile: 479px;
$mobileLarge: 576px;
$ipad: 768px;
$large: 991px;
$extraLarge: 1024px;
$desktop: 1366px;



// Positions
$position_fixed: fixed;
$position_relative: relative;
$position_absolute: absolute;

// Text
$text_left: left;
$text_center: center;
$text_right :right;
$text_uppercase: uppercase;
$text_lowercase: lowercase;
$text_capitalize: capitalize;
$text_decoration_none: none;
$text_underline: underline;

//  Properties
$d_block: block;
$d_inline_block: inline-block;
$d_none: none;
$d_flex: flex;
$d_inline_flex: inline-flex;

//align item
$align_items_center : center;
$align_items_end: flex-end;
$align_items_start: flex-start;
$align_items_baseline: baseline;

//justify
$justify_content_center: center;
$justify_content_between: space-between;
$justify_content_around: space-around;
$justify_content_evenly: space-evenly;
$justify_content_start: flex-start;
$justify_content_end: flex-end;
$flex_direction_column: column;
$nowrap: nowrap;
$flex_wrap: wrap;

//word break
$word_break: break-all;
$white_space_nowrap: nowrap;
$white_space_wrap: normal;

//background image
$background-norepeat: no-repeat;
$background_size_cover: cover;

// Radius
$radius_0: 0;
$radius_6: 6px;
$radius_2: 2px;
$radius_5: 5px;
$radius_10: 10px;
$radius_50: 50%;
$radius_20: 20px;

//font size

$f12: 12px;
$f13: 13px;
$f32: 32px;
$f24: 24px;
$f28: 28px;
$f16: 16px;
$f14: 14px;
$f22: 22px;
$f18: 18px;
$f11: 11px;
$f12: 12px;
$f10: 10px;




//font Weight
$bold: 700;
$medium: 500;
$semi_bold: 600;
$extra_bold: 800;
$font_black: 900;
$normal: 400;
$light: 300;
$extra_light: 200;


//line height
$lineheight_28: 28px;
$lineheight_40: 40px;
$lineheight_22: 22px;
$lineheight_20: 20px;
$lineheight_35: 35px;
$lineheight_25: 25px;
$lineheight_21: 21px;
$lineheight_18: 18px;
$lineheight_12: 12px;

//letter spacing
$letter_02: 0.02em;
$letter2: 2px;
$letter7: 0.7px;
$letter3125: 0.3125px;
$letter1: 1px;
//width
$w_100 :100%;
$w_43: 43.3%;
$w_56: 56%;
$w_32: 32px;
$w_554: 554px;
$w_452: 452px;
$w_154: 154px;
$w_267: 267px;
$w_18: 18px;
$w_16px: 16px;
$w_40: 40%;
$w_120: 120px;
$w_50: 50%;
$w_12: 12%;
$w_16: 16%;
$w_85: 85%;
$w_21: 21.8%;
$w_19: 19px;
$w_60: 60%;
$w_160: 160px;
$w_696: 696px;
$w_437: 437px;
$w_201: 201px;
$w_224: 224px;
$w_116px: 116px;
$w_max_content: max-content;


//height
$h_100: 100%;
$h_32: 32px;
$h_15: 15px;
$h_17: 17px;
$h_40: 40px;
$h_19: 19px;

//margin
$m_auto: 0 auto;

//box shadow
$form_box-shadow: 0px 0px 20px rgba(214, 214, 214, 0.5);